<template>
  <div>
    <NavBarGuest v-if="!isUserStaff"></NavBarGuest>
    <div class="details power-bi">
      <div class="title-block pt-4">
        <button class="return" onclick="history.back()">
          <v-icon>mdi-keyboard-return</v-icon>
        </button>
        <h1 class="text-h2 mb-4 text-center">Rapport interne</h1>
      </div>
      <div class="button-container">
        <router-link class="btn-link" to="/reports/staff">
          <v-btn large color="primary" class="btn ma-2 btn-trainee">
            Rapports
          </v-btn>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import NavBarGuest from "@/components/NavBarGuest.vue";
import authExpirationMixin from "@/mixins/isLogged.js";

export default {
  mixins: [authExpirationMixin],
  name: "ReportsPBI",
  components: {
    NavBarGuest,
  },

  computed: {
    isUserStaff() {
      return localStorage.getItem("staff") === "true";
    },
  },
};
</script>

<style lang="scss"></style>
