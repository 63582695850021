<template>
  <div class="details trainee_associatedTBS">
    <v-card class="data-table">
      <div class="d-flex flex-row align-start background-details flex-wrap">
        <v-list class="details-content d-flex flex-wrap pt-0 pb-8 w-100">
          <h2 class="px-10 py-3 mt-8 text-h6 font-weight-bold">
            Parcours associés :
          </h2>
          <v-data-table
            :headers="ParcoursHeaders"
            :items="trainee.Parcours"
            class="w-100 related-data-table mx-10"
            color="table"
            :loading="loading"
          >
            <template v-slot:[`item.TXT_TrainingBlocName`]="{ item }">
              <router-link :to="`/parcours/${item.ID_D_TrainingBloc}`">{{
                item.TXT_TrainingBlocName
              }}</router-link>
            </template>

            <template v-slot:[`item.TXT_SubscriptionStatus`]="{ item }">
              <v-chip :color="getStatusColor(item.TXT_SubscriptionStatus)">
                {{ item.TXT_SubscriptionStatus }}
              </v-chip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div class="actions">
                <v-btn :disabled="item.BIT_Cancelled">
                  <TrainingBlocExtentSubscriptionButton
                    v-if="item"
                    :item="item"
                    :ActionAddTitle="ActionAddTitle"
                    @selected-end-date-changed="handleSelectedEndDateChanged"
                    @exam-date-changed="handleExamDateChanged"
                    @refresh-training-subscriptions="
                      getTrainingblocsubscriptions
                    "
                  />
                </v-btn>
                <v-btn :disabled="item.BIT_Cancelled">
                  <TrainingBlocPauseSubscriptionButton
                    v-if="item"
                    :item="item"
                    :trainees-data="traineesData"
                    :ActionPauseTitle="ActionPauseTitle"
                    @selected-begin-pause="handleSelectedBeginPause"
                    @selected-resume-date="handleSelectedResumeDate"
                    @selected-end-date-changed="handleSelectedEndDateChanged"
                    @exam-date-changed="handleExamDateChanged"
                    @refresh-training-subscriptions="
                      getTrainingblocsubscriptions
                    "
                  />
                </v-btn>
                <v-btn :disabled="item.BIT_Cancelled">
                  <TrainingBlocStopSubscriptionButton
                    v-if="item"
                    :item="item"
                    :trainees-data="traineesData"
                    :ActionDeleteTitle="ActionDeleteTitle"
                    @selected-end-date-changed="handleSelectedEndDateChanged"
                    @exam-date-changed="handleExamDateChanged"
                    @refresh-training-subscriptions="
                      getTrainingblocsubscriptions
                    "
                  />
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-list>
      </div>
    </v-card>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import authExpirationMixin from "@/mixins/isLogged.js";
import { useChipStatusColor } from "@/reusables/chipStatusColor.js";
import TrainingBlocExtentSubscriptionButton from "./TrainingBlocExtentSubscriptionButton.vue";
import TrainingBlocPauseSubscriptionButton from "./TrainingBlocPauseSubscriptionButton.vue";
import TrainingBlocStopSubscriptionButton from "./TrainingBlocStopSubscriptionButton.vue";
import axios from "axios";
import { base_url, trainees_urls, header } from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "TraineeTBSTable",
  components: {
    TrainingBlocExtentSubscriptionButton,
    TrainingBlocPauseSubscriptionButton,
    TrainingBlocStopSubscriptionButton,
  },
  data() {
    return {
      trainee: {},
      ParcoursHeaders: [
        {
          title: "Nom",
          value: "TXT_TrainingBlocName",
          key: "TXT_TrainingBlocName",
        },
        {
          title: "LMS",
          value: "TXT_LMS",
          key: "TXT_LMS",
        },
        {
          title: "Date de début",
          value: "DAT_SubscriptionStart",
          key: "DAT_SubscriptionStart",
        },
        {
          title: "Date de fin",
          value: "DAT_SubscriptionEnd",
          key: "DAT_SubscriptionEnd",
        },
        {
          title: "Date d'examen",
          value: "DAT_FinalTestScheduled",
          key: "DAT_FinalTestScheduled",
        },
        {
          title: "Objectif",
          value: "NUM_TargetScore",
          key: "NUM_TargetScore",
        },
        {
          title: "Status",
          value: "TXT_SubscriptionStatus",
          key: "TXT_SubscriptionStatus",
        },
        {
          title: "Actions",
          value: "actions",
          key: "actions",
        },
      ],

      dialog: false,
      dialogTitle: "",
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      ActionAddTitle: "Prolongation de la session de formation",
      ActionPauseTitle:
        "Voulez-vous mettre en pause la formation de cet(te) apprenant(e) ?",
      ActionDeleteTitle:
        "Voulez-vous désinscrire cet(te) apprenant(e) de cette formation ?",
      dialogTraineeAction: false,
      traineesData: [],
      selectedTrainee: {},

      currentTrainingblocSubscription: {},
      newSelectedEndDate: null,
      newSelectedBeginPauseDate: null,
      newSelectedResumeDate: null,
      newSelectedExamDate: null,
      selectedEndPause: null,
      selectedExamDate: null,

      loading: false,

      rules: {
        required: (value) => !!value || "Ce champ est requis.",
      },
    };
  },
  props: {
    status: String,
  },
  methods: {
    async getTrainingblocsubscriptions() {
      this.loading = true;

      const traineeId = this.$route.params.id;
      let url = `${base_url}${trainees_urls.get_details(
        traineeId
      )}?&timestamp=${new Date().getTime()}`;

      try {
        const response = await axios.get(url, header);
        this.trainee = response.data;
      } catch (error) {
        console.error("Error fetching subscriptions details:", error);
      } finally {
        this.loading = false;
      }
    },

    getStatusColor(status) {
      return useChipStatusColor(status);
    },

    handleSelectedEndDateChanged(newEndDate) {
      this.newSelectedEndDate = newEndDate;
    },

    handleExamDateChanged(newExamDate) {
      this.selectedExamDate = newExamDate;
    },

    handleSelectedBeginPause(newSelectedBeginPauseDate) {
      this.selectedBeginPauseDate = newSelectedBeginPauseDate;
    },

    handleSelectedResumeDate(newSelectedResumeDate) {
      this.selectedResumeDate = newSelectedResumeDate;
    },
  },

  created() {
    this.getTrainingblocsubscriptions();
  },

  mounted() {
    document.title = "Apprenant·e - Détails";
  },
};
</script>

<style lang="scss">
.details.trainee_associatedTBS {
  .data-table .v-table .v-data-table__td {
    &:nth-child(1) {
      width: 100% !important;
    }
    &:not(:nth-child(1)) {
      width: 33% !important;
    }
  }

  .v-chip.v-chip--size-default {
    min-height: 30px;
    height: unset !important;
    padding: 4px 11px;
    white-space: normal;
    text-align: center;
    line-height: 18px;
  }
}

tr {
  justify-content: center;
}
.w-50_fix {
  width: calc(50% - 1px);
  margin-right: 1px;
}
</style>
