export function useChipStatusColor(status) {
  switch (status) {
    case "Inscrit.e":
      return "success";
    case "Mis en pause":
      return "warning";
    case "Reprise":
      return "warning";
    case "Désinscrit.e":
      return "error";
    case "Prolongation":
      return "info";
    case "Prolongé":
      return "info";
    default:
      return "primary";
  }
}
