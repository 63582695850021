<template>
  <div>
    <v-form ref="form" v-model="valid">
      <v-btn @click="openModulesDialog" class="btn-data-table mb-5">
        Sélectionner un Module
      </v-btn>
      <v-chip v-if="selectedModule" class="mb-3" @click:close="removeModule">
        {{ selectedModule.TXT_Module }}
      </v-chip>

      <v-dialog
        v-model="modulesDialog"
        transition="dialog-top-transition"
        persistent
        min-width="800"
      >
        <v-card class="rounded-lg pa-4" color="dialog">
          <v-card-title class="headline">Sélectionner un Module</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Rechercher"
              clearable
              single-line
              hide-details
              @keyup.enter="getModules(true)"
              @click:prepend-inner="getModules(true)"
              @click:clear="resetSearch"
              density="compact"
              bg-color="grey-darken-3"
              color="primary"
            ></v-text-field>
            <v-data-table-server
              v-model:page="currentPage"
              :page="currentPage"
              :headers="headers"
              :items="Modules"
              v-model:options="options"
              items-per-page-text="Nombre d'élements par page."
              no-data-text="Aucune donnée"
              :items-length="totalItems"
              :loading="isLoading"
              @update:options="updateOptions"
              density="compact"
            >
              <template v-slot:[`item.select`]="{ item }">
                <v-checkbox
                  class="d-flex justify-center ma-0 pa-0"
                  v-model="selectedModuleId"
                  :value="item.ID_D_Module"
                  @change="selectModule(item)"
                  density="compact"
                ></v-checkbox>
              </template>
            </v-data-table-server>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="modulesDialog = false"
              >Fermer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div class="d-flex gap-20">
        <div class="w-100">
          <v-textarea
            rows="3"
            auto-grow
            v-model="form.TXT_Question"
            label="Question"
            variant="solo"
            class="form-input required"
            :rules="[rules.required]"
          />
          <v-textarea
            rows="3"
            auto-grow
            v-model="form.TXT_RightAnswer"
            label="Bonne réponse"
            variant="solo"
            class="form-input required"
            :rules="[rules.required]"
          />
        </div>

        <div class="w-100">
          <v-textarea
            rows="3"
            auto-grow
            v-model="form.TXT_WrongAnswer1"
            label="Mauvaise réponse 1"
            variant="solo"
            class="form-input required"
            :rules="[rules.required]"
          />
          <v-textarea
            rows="3"
            auto-grow
            v-model="form.TXT_WrongAnswer2"
            label="Mauvaise réponse 2"
            variant="solo"
            class="form-input required"
            :rules="[rules.required]"
          />
        </div>
      </div>

      <div class="d-flex gap-20">
        <v-textarea
          rows="4"
          auto-grow
          v-model="form.TXT_Explication"
          label="Explication"
          variant="solo"
          class="form-input required"
          :rules="[rules.required]"
        />
        <div class="">
          <v-textarea
            rows="1"
            auto-grow
            v-model="form.TXT_Source"
            label="Source"
            variant="solo"
            class="form-input required"
            :rules="[rules.required]"
          />
          <v-textarea
            rows="1"
            auto-grow
            v-model="form.TXT_ExpertName"
            label="Nom de l'expert"
            variant="solo"
            class="form-input"
          />
        </div>
      </div>

      <div class="d-flex gap-20 justify-center align-center">
        <v-checkbox
          class="text-h6 fat-checkbox flex-end"
          v-model="form.BIT_Status"
          label="Actif ?"
          hide-details
        />
        <v-checkbox
          class="text-h6 fat-checkbox"
          v-model="form.BIT_Validate"
          label="Validé ?"
          hide-details
        />
      </div>

      <v-btn
        class="px-16 py-6 d-flex rounded-lg mb-2 elevation-4"
        color="primary"
        :loading="loading"
        :disabled="!valid"
        @click="submitForm"
      >
        Ajouter la Question
      </v-btn>
    </v-form>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import {
  base_url,
  questions_urls,
  modules_urls,
  header,
} from "@/API/config.js";

export default {
  data() {
    return {
      valid: false,
      loading: false,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",
      search: "",
      Modules: [],
      selectedModule: null,
      selectedModuleId: null,
      isLoading: false,
      modulesDialog: false,
      totalItems: 0,
      headers: [
        {
          title: "Sélectionner",
          value: "select",
          sortable: false,
          width: "100px",
        },
        { title: "Module", value: "TXT_Module" },
        { title: "Description", value: "TXT_Description" },
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      currentPage: 1,
      cacheVersion: null,

      form: {
        TXT_Question: "",
        TXT_ExpertName: "",
        BIT_Status: "false",
        BIT_Validate: "false",
        TXT_Source: "",
        TXT_Explication: "",
        TXT_RightAnswer: "",
        TXT_WrongAnswer1: "",
        TXT_WrongAnswer2: "",
        TXT_CreatedBy: this.$store.state.auth.id,
        TXT_LastEditedBy: this.$store.state.auth.id,
      },
      rules: {
        required: (value) => !!value || "Champ requis.",
      },
    };
  },
  methods: {
    async initializeCacheBusting() {
      const cacheVersionKeys = Object.keys(localStorage).filter((key) =>
        key.startsWith("cacheVersion")
      );

      if (cacheVersionKeys.length > 0) {
        const latestCacheVersion = cacheVersionKeys.reduce((latest, key) => {
          const version = parseInt(localStorage.getItem(key));
          return version > latest ? version : latest;
        }, 0);

        return latestCacheVersion.toString();
      } else {
        const response = await axios.get(
          `${base_url}${modules_urls.get_all}`,
          header
        );
        const cacheVersion = response.data.results.cache_version;
        localStorage.setItem("cacheVersion", cacheVersion);
        return cacheVersion;
      }
    },

    updateCacheBusting(newCacheVersion) {
      localStorage.setItem(`cacheVersion_${newCacheVersion}`, newCacheVersion);
    },

    async getModules(resetPage = false) {
      if (resetPage) {
        this.currentPage = 1;
        this.options.page = 1;
      }

      this.isLoading = true;
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${modules_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${cacheVersion}`;
      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }
      try {
        const response = await axios.get(url, header);
        this.totalItems = response.data.count;
        this.Modules = response.data.results.results;

        if (response.data.results.cache_version !== cacheVersion) {
          this.updateCacheBusting(response.data.results.cache_version);
          await this.refreshData();
        }
      } catch (error) {
        console.error("Error fetching modules:", error);
      } finally {
        this.isLoading = false;
      }
    },

    async refreshData() {
      const newCacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${modules_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${newCacheVersion}`;
      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }
      try {
        const response = await axios.get(url, header);
        this.totalItems = response.data.count;
        this.Modules = response.data.results.results;
      } catch (error) {
        console.error("Error refreshing modules data:", error);
      }
    },

    resetSearch() {
      this.search = "";
      this.getModules(true);
    },

    updateOptions(newOptions) {
      this.options = newOptions;
      this.getModules();
    },

    selectModule(module) {
      this.selectedModule = module;
      this.modulesDialog = false;
    },

    removeModule() {
      this.selectedModule = null;
      this.selectedModuleId = null;
    },

    openModulesDialog() {
      this.modulesDialog = true;
      this.getModules();
    },

    async submitForm() {
      const url = `${base_url}${questions_urls.get_all}`;

      const timestamp = new Date().toISOString().slice(0, -1) + "000Z";
      this.form.DAT_Created = timestamp;
      this.form.DAT_LastEdited = timestamp;

      if (this.form.BIT_Status === "") {
        this.form.BIT_Status = "false";
      }

      if (this.form.BIT_Validate === "") {
        this.form.BIT_Validate = "false";
      }

      if (!this.form.TXT_ExpertName) {
        this.form.TXT_ExpertName = null;
      }

      this.form.Modules = this.selectedModule
        ? this.selectedModule.ID_D_Module
        : null;

      this.loading = true;

      try {
        await axios.post(url, this.form, header);
        this.resetForm();
        this.snackbarMessage = `Une nouvelle <strong>Question</strong> a été ajoutée avec succès`;
        this.snackbarColor = "success";
      } catch (error) {
        console.error(error);
        this.snackbarMessage = `Une erreur s'est produite lors de l'ajout d'une <strong>Question</strong>`;
        this.snackbarColor = "error";
      } finally {
        this.loading = false;
        this.snackbar = true;
      }
    },

    resetForm() {
      this.form = {
        TXT_Question: "",
        TXT_ExpertName: "",
        BIT_Status: "false",
        BIT_Validate: "false",
        TXT_Source: "",
        TXT_Explication: "",
        TXT_RightAnswer: "",
        TXT_WrongAnswer1: "",
        TXT_WrongAnswer2: "",
        TXT_CreatedBy: this.$store.state.auth.id,
        TXT_LastEditedBy: this.$store.state.auth.id,
      };
      this.selectedModule = null;
      this.selectedModuleId = null;
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>

<style lang="scss">
.fat-checkbox {
  label {
    font-size: 20px;
    font-weight: 500;
  }
  &.flex-end .v-checkbox-btn {
    justify-content: flex-end;
  }
}
</style>
