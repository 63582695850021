<template>
  <div class="details">
    <NavBar></NavBar>
    <div class="title-block">
      <button class="return" onclick="history.back()">
        <v-icon>mdi-keyboard-return</v-icon>
      </button>
      <h1 class="text-h2 my-8 text-center">Détails du Sous-Thème</h1>
    </div>
    <div class="mx-16 pb-10 pl-16">
      <v-card class="data-table">
        <v-card-title
          class="title-details rounded-lg text-center d-flex justify-center"
        >
          Informations
        </v-card-title>
        <div class="d-flex flex-row align-start background-details flex-wrap">
          <v-list class="details-content d-flex flex-wrap pt-0 pb-8 w-100">
            <v-list-item class="w-100 pa-0" v-for="key in keyOrder1" :key="key">
              <div class="details-table-infos px-10 py-3">
                <div class="details-table-infos__cell">
                  <div :class="{ 'text-primary': key === 'themes' }">
                    <strong>{{ keyTextMap[key] }} :</strong>
                  </div>
                  <div class="details-table-infos-content">
                    <div v-if="key === 'themes'">
                      {{ subtheme.Themes }}
                    </div>
                    <template v-else>
                      {{ subtheme[key] }}
                    </template>
                  </div>
                </div>
                <div
                  v-if="key !== 'themes'"
                  class="details-table-infos__edit"
                  @click="openUpdateDialog(key)"
                >
                  <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                </div>
              </div>
            </v-list-item>

            <div class="w-100">
              <h2 class="px-10 mt-6 text-h6 font-weight-bold">
                Commentaires :
                <v-btn icon class="ml-2" @click="openAddCommentDialog">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </h2>

              <div class="comments-list px-10 mt-4 d-flex">
                <v-card
                  v-for="comment in reversedComments"
                  :key="comment.ID_FS_CommentsSubTheme"
                  class="comment-block pa-2 mb-4 rounded-lg"
                  color="secondary"
                >
                  <div class="comment-header d-flex justify-between">
                    <h3 class="comment-date mb-2 d-flex">
                      <span class="comment-date__user">
                        {{ comment.ID_NDAppUser }}
                      </span>
                      &nbsp;-&nbsp;
                      <span class="comment-date__date">
                        {{ formatDate(comment.DAT_Created) }}
                      </span>
                    </h3>
                    <div class="comment-actions">
                      <div
                        class="comment-edit"
                        @click="openCommentUpdateDialog(comment)"
                      >
                        <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                      </div>
                      <div
                        class="comment-delete"
                        @click="openDeleteDialog(comment)"
                      >
                        <v-icon icon="mdi-delete" size="x-large"></v-icon>
                      </div>
                    </div>
                  </div>
                  <p class="comment-text">{{ comment.TXT_Comment }}</p>
                </v-card>
              </div>
            </div>

            <div class="px-10 py-3 mt-8 d-flex align-center">
              <h2 class="text-h6 font-weight-bold">Modules associés :</h2>
              <v-btn
                icon
                class="details-table-infos__edit ml-2"
                @click="openUpdateDialog('Modules')"
              >
                <v-icon icon="mdi-pencil" size="x-large"></v-icon>
              </v-btn>
            </div>
            <v-data-table
              :headers="ModulesHeaders"
              :items="subtheme.Modules"
              class="w-100 related-data-table mx-10"
              color="table"
            >
              <template v-slot:[`item.TXT_Module`]="{ item }">
                <router-link :to="`/modules/${item.ID_D_Module}`">{{
                  item.TXT_Module
                }}</router-link>
              </template>
            </v-data-table>

            <div
              class="d-flex w-100 mt-6 px-10 align-center justify-space-between"
            >
              <div class="details-dates d-flex">
                <div
                  class="w-100 pa-0 details-dates__element"
                  v-for="key in keyOrder2"
                  :key="key"
                >
                  <div class="element">
                    <div>
                      <strong>{{ keyTextMap[key] }} :</strong>
                    </div>
                    <div>
                      <!-- Utilisation de la méthode formatDate si la clé est une date -->
                      {{
                        key === "DAT_LastEdited" || key === "DAT_Created"
                          ? formatDate(subtheme[key])
                          : subtheme[key]
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <v-btn color="primary" @click="openDeleteSubThemeDialog(subtheme)"
                >Supprimer le sous-thème</v-btn
              >
            </div>
          </v-list>
        </div>
      </v-card>
    </div>

    <v-dialog
      v-if="fieldToUpdate === 'Modules'"
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      min-width="800"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="search"
            clearable
            prepend-inner-icon="mdi-magnify"
            label="Rechercher..."
            single-line
            hide-details
            @keyup.enter="getModules(true)"
            @click:prepend-inner="getModules(true)"
            @click:clear="resetSearch"
            density="compact"
            bg-color="grey-darken-3"
            color="primary"
          ></v-text-field>
          <v-data-table-server
            :show-current-page="true"
            :headers="DialogModulesHeaders"
            v-model:page="currentPage"
            :items="PopupModules"
            :items-length="totalModules"
            items-per-page-text="Nombre d'élements par page."
            :loading="isLoading"
            no-data-text="Aucune donnée"
            @update:options="updateOptions"
            item-key="ID_D_Module"
            density="compact"
          >
            <template v-slot:[`item.selected`]="{ item }">
              <v-checkbox
                class="d-flex justify-center ma-0 pa-0"
                v-model="item.selected"
                @change="updateSelectedItems(item)"
                density="compact"
              ></v-checkbox>
            </template>
          </v-data-table-server>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Annuler</v-btn>
          <v-btn
            color="primary"
            text
            @click="confirmUpdate"
            :loading="isUpdating"
            :disabled="isUpdating"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="fieldToUpdate !== 'Modules'"
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-textarea
            v-if="
              fieldToUpdate !== 'themes' &&
              fieldToUpdate !== 'Modules' &&
              fieldToUpdate !== 'TXT_Description' &&
              fieldToUpdate !== 'TXT_LearningObjectif'
            "
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newFieldValue"
            :rules="[rules.required]"
            color="primary"
          ></v-textarea>

          <v-textarea
            v-else-if="
              fieldToUpdate === 'TXT_Description' ||
              fieldToUpdate === 'TXT_LearningObjectif'
            "
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newFieldValue"
            color="primary"
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Annuler</v-btn>
          <v-btn color="primary" text @click="confirmUpdate">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addCommentDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">Ajouter un commentaire</v-card-title>
        <v-card-text>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newComment"
            :rules="[rules.required]"
            color="primary"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addCommentDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="addComment">Ajouter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">Supprimer le commentaire</v-card-title>
        <v-card-text
          >Voulez-vous vraiment supprimer ce commentaire ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="deleteComment">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteSubThemeDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline"
          >Êtes-vous sûr de vouloir supprimer ce sous-thème ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteSubThemeDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="deleteSubTheme">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import {
  base_url,
  subthemes_urls,
  comments_urls,
  user_urls,
  modules_urls,
  header,
} from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "SubThemeSlug",
  components: {
    NavBar,
  },
  data() {
    return {
      subtheme: {
        comments: [],
      },
      keyTextMap: {
        TXT_SubTheme: "Sous-thème",
        TXT_Description: "Description",
        TXT_LearningObjectif: "Objectif pédagogique",
        TXT_Ref: "Ref",
        DAT_Created: "Création",
        TXT_LastEditedBy: "Modifié par",
        DAT_LastEdited: "Dernière modification",
        themes: "Thème associé",
        Modules: "Modules associés",
      },
      keyOrder1: [
        "TXT_SubTheme",
        "TXT_Description",
        "TXT_LearningObjectif",
        "TXT_Ref",
        "themes",
      ],
      keyOrder2: ["DAT_Created", "DAT_LastEdited", "TXT_LastEditedBy"],

      ModulesHeaders: [
        {
          title: "Module",
          value: "TXT_Module",
          key: "TXT_Module",
        },
        {
          title: "Description",
          value: "TXT_Description",
          key: "TXT_Description",
        },
        {
          title: "Objectif pédagogique",
          value: "TXT_LearningObjectif",
          key: "TXT_LearningObjectif",
        },
      ],

      DialogModulesHeaders: [
        {
          title: "Sélectionner",
          value: "selected",
          key: "selected",
          sortable: false,
          width: "100px",
        },
        {
          title: "Module",
          value: "TXT_Module",
          key: "TXT_Module",
          sortable: false,
        },
        {
          title: "Description",
          value: "TXT_Description",
          key: "TXT_Description",
          sortable: false,
        },
        {
          title: "Objectif pédagogique",
          value: "TXT_LearningObjectif",
          key: "TXT_LearningObjectif",
          sortable: false,
        },
      ],

      dialog: false,
      dialogTitle: "",
      newFieldValue: "",
      fieldToUpdate: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      currentCommentID: null,
      addCommentDialog: false,
      newComment: "",
      deleteDialog: false,
      commentToDelete: null,
      subthemeToDelete: null,
      deleteSubThemeDialog: false,

      Themes: [],
      Modules: [],
      isLoading: false,
      isUpdating: false,

      totalModules: 0,
      currentPage: 1,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      PopupModules: [],
      selectedItems: new Set(),
      search: "",

      rules: {
        required: (value) => !!value || "Ce champ est requis.",
      },
    };
  },

  methods: {
    updateOptions(newOptions) {
      this.options = newOptions;
    },
    async initializeCacheBusting() {
      const cacheVersionKeys = Object.keys(localStorage).filter((key) =>
        key.startsWith("cacheVersion")
      );

      if (cacheVersionKeys.length > 0) {
        const latestCacheVersion = cacheVersionKeys.reduce((latest, key) => {
          const version = parseInt(localStorage.getItem(key));
          return version > latest ? version : latest;
        }, 0);

        return latestCacheVersion.toString();
      } else {
        const response = await axios.get(
          `${base_url}${modules_urls.get_all}`,
          header
        );
        const cacheVersion = response.data.results.cache_version;
        localStorage.setItem("cacheVersion", cacheVersion);
        return cacheVersion;
      }
    },

    updateCacheBusting(newCacheVersion) {
      localStorage.setItem(`cacheVersion_${newCacheVersion}`, newCacheVersion);
    },

    async refreshData() {
      const newCacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${modules_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${newCacheVersion}`;
      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }
      try {
        const response = await axios.get(url, header);
        this.totalModules = response.data.count;
        this.PopupModules = response.data.results.results.map((module) => ({
          ...module,
          selected: this.selectedItems.has(module.ID_D_Module),
        }));
      } catch (error) {
        console.error("Error refreshing modules data:", error);
      }
    },

    resetSearch() {
      this.search = "";
      this.getModules(true);
    },

    updateSelectedItems(item) {
      if (item.selected) {
        this.selectedItems.add(item.ID_D_Module);
      } else {
        this.selectedItems.delete(item.ID_D_Module);
      }
    },

    openDeleteSubThemeDialog(subtheme) {
      this.subthemeToDelete = subtheme;
      this.deleteSubThemeDialog = true;
    },

    async fetchUserDetails() {
      const userId = localStorage.getItem("id");
      if (userId) {
        try {
          const response = await this.getUserDetails(userId);
          this.userDetails = response.data;
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des détails de l'utilisateur:",
            error
          );
          this.snackbarMessage =
            "Erreur lors de la vérification des droits utilisateur.";
          this.snackbarColor = "error";
          this.snackbar = true;
        }
      } else {
        this.snackbarMessage =
          "Impossible de vérifier les droits utilisateur. Veuillez vous reconnecter.";
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },

    async deleteSubTheme() {
      const subthemeId = this.$route.params.id;
      const url = `${base_url}${subthemes_urls.archive(subthemeId)}`;

      await this.fetchUserDetails();

      if (!this.userDetails?.is_superuser) {
        this.snackbarMessage =
          "Vous n'avez pas les droits nécessaires pour supprimer ce sous-thème.";
        this.snackbarColor = "error";
        this.snackbar = true;
        return;
      }

      if (this.subthemeToDelete) {
        const payload = {
          is_admin: true,
        };

        try {
          await axios.post(url, payload, header);
          this.snackbarMessage = `Le Sous-Thème a été supprimé.`;
          this.snackbar = true;
          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);
          this.$router.push("/subthemes");
        } catch (error) {
          console.error("Erreur lors de la suppression du sous-thème:", error);

          this.snackbarMessage = `Erreur lors de la suppression du <strong>Sous-Thème</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        }
        this.deleteSubThemeDialog = false;
      }
    },

    async getModules(resetPage = false) {
      if (resetPage) {
        this.currentPage = 1;
        this.options.page = 1;
      }

      this.isLoading = true;
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${modules_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${cacheVersion}`;
      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }
      try {
        const response = await axios.get(url, header);
        this.totalModules = response.data.count;
        this.PopupModules = response.data.results.results.map((module) => ({
          ...module,
          selected: this.selectedItems.has(module.ID_D_Module),
        }));
        this.currentPage = page;

        if (response.data.results.cache_version !== cacheVersion) {
          this.updateCacheBusting(response.data.results.cache_version);
          await this.refreshData();
        }
      } catch (error) {
        console.error("Error fetching modules:", error);
      } finally {
        this.isLoading = false;
      }
    },

    getUserDetails(id) {
      const url = `${base_url}${user_urls.get_details(id)}`;
      return axios.get(url, header);
    },

    getSubThemeDetails() {
      const subthemeId = this.$route.params.id;
      const url = `${base_url}${subthemes_urls.get_details(subthemeId)}`;
      const commentsUrl = `${base_url}${comments_urls.subtheme_all}`;

      axios
        .get(url, header)
        .then((response) => {
          this.subtheme = {
            ...response.data,
          };
          if (this.subtheme.TXT_LastEditedBy) {
            return this.getUserDetails(this.subtheme.TXT_LastEditedBy);
          } else {
            return Promise.resolve(null);
          }
        })
        .then((userResponse) => {
          if (userResponse) {
            this.subtheme.TXT_LastEditedBy = userResponse.data.username;
          }
          return axios.get(commentsUrl, header);
        })

        .then((commentsResponse) => {
          this.subtheme.comments = commentsResponse.data.filter(
            (comment) => comment.ID_D_SubTheme === parseInt(subthemeId)
          );
          const uniqueUserIds = [
            ...new Set(
              this.subtheme.comments.map((comment) => comment.ID_NDAppUser)
            ),
          ];
          return Promise.all(
            uniqueUserIds.map((userId) => this.getUserDetails(userId))
          );
        })
        .then((userResponses) => {
          userResponses.forEach((userResponse) => {
            const username = userResponse.data.username;
            this.subtheme.comments.forEach((comment) => {
              if (comment.ID_NDAppUser === userResponse.data.id) {
                comment.ID_NDAppUser = username;
              }
            });
          });
        })
        .catch((error) => {
          console.error("Error fetching subtheme details:", error);
        });
    },

    openUpdateDialog(key) {
      this.dialogTitle = `Modifier ${this.keyTextMap[key]}`;
      this.fieldToUpdate = key;
      if (key === "themes") {
        this.newFieldValue = this.subtheme[key].map(
          (theme) => theme.ID_D_Theme__TXT_Theme
        );
      } else if (key === "Modules") {
        this.getModules();
        this.selectedItems = new Set(
          this.subtheme.Modules.map((module) => module.ID_D_Module)
        );
      } else {
        this.newFieldValue = this.subtheme[key];
      }
      this.dialog = true;
    },

    async confirmUpdate() {
      this.isUpdating = true;
      try {
        if (this.fieldToUpdate === "Modules") {
          this.newFieldValue = Array.from(this.selectedItems);
          await this.updateSubTheme(this.fieldToUpdate, this.newFieldValue);
        } else if (
          this.fieldToUpdate === "TXT_Description" ||
          this.fieldToUpdate === "TXT_LearningObjectif" ||
          (this.newFieldValue !== undefined &&
            this.newFieldValue !== null &&
            this.newFieldValue !== "")
        ) {
          if (this.fieldToUpdate === "themes") {
            const newThemes = this.Themes.filter((theme) =>
              this.newFieldValue.includes(theme.ID_D_Theme__TXT_Theme)
            );
            await this.updateSubTheme(this.fieldToUpdate, newThemes);
          } else if (this.fieldToUpdate === "TXT_Comment") {
            await this.updateComment(this.currentCommentID, this.newFieldValue);
          } else {
            await this.updateSubTheme(this.fieldToUpdate, this.newFieldValue);
          }
        }
      } catch (error) {
        console.error("Error during update:", error);
      } finally {
        this.isUpdating = false;
        this.dialog = false;
      }
    },

    updateComment(commentId, newComment) {
      const url = `${base_url}${comments_urls.subtheme_details(commentId)}`;

      axios
        .put(url, { TXT_Comment: newComment }, header)
        .then(() => {
          const commentIndex = this.subtheme.comments.findIndex(
            (comment) => comment.ID_FS_CommentsSubTheme === commentId
          );
          this.subtheme.comments[commentIndex].TXT_Comment = newComment;

          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);

          this.snackbarMessage = `Le commentaire a été mis à jour.`;
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          console.error("Error updating comment:", error);

          this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du <strong>Commentaire</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    async updateModule(moduleId, subThemeId) {
      const url = `${base_url}${modules_urls.get_details(moduleId)}`;
      try {
        const response = await axios.get(url, header);
        const moduleData = response.data;

        moduleData.SubThemes = subThemeId;

        const userDetails = await this.getUserDetails(
          this.$store.state.auth.id
        );
        moduleData.TXT_LastEditedBy = userDetails.data.id;

        moduleData.DAT_LastEdited = new Date().toISOString();

        const updateResponse = await axios.put(url, moduleData, header);

        if (updateResponse.data && updateResponse.data.cache_version) {
          this.updateCacheBusting(updateResponse.data.cache_version);
        } else {
          const currentVersion = await this.initializeCacheBusting();
          this.updateCacheBusting(String(parseInt(currentVersion) + 1));
        }
      } catch (error) {
        console.error(
          `Erreur lors de la mise à jour du module ${moduleId}:`,
          error
        );
        throw error;
      }
    },

    async updateSubTheme(field, newValue) {
      const subthemeId = this.$route.params.id;
      const url = `${base_url}${subthemes_urls.update(subthemeId)}`;

      const updatedData = {
        TXT_SubTheme: this.subtheme.TXT_SubTheme,
        TXT_Description: this.subtheme.TXT_Description,
        TXT_LearningObjectif: this.subtheme.TXT_LearningObjectif,
        TXT_Ref: this.subtheme.TXT_Ref,
        DAT_Created: this.subtheme.DAT_Created,
        [field]: newValue,
        DAT_LastEdited: new Date().toISOString(),
        TXT_CreatedBy: this.subtheme.TXT_CreatedBy,
        TXT_LastEditedBy: this.$store.state.auth.id,
      };

      if (field === "Modules") {
        try {
          const currentModules = this.subtheme.Modules.map(
            (module) => module.ID_D_Module
          );

          const modulesToAdd = newValue.filter(
            (id) => !currentModules.includes(id)
          );
          const modulesToRemove = currentModules.filter(
            (id) => !newValue.includes(id)
          );

          for (const moduleId of modulesToAdd) {
            await this.updateModule(moduleId, subthemeId);
          }
          for (const moduleId of modulesToRemove) {
            await this.updateModule(moduleId, null);
          }

          this.subtheme.Modules = newValue.map((id) => ({ ID_D_Module: id }));
        } catch (error) {
          console.error("Erreur lors de la mise à jour des modules:", error);
          this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour des modules`;
          this.snackbarColor = "error";
          this.snackbar = true;
          return;
        }
      }

      try {
        const response = await axios.put(url, updatedData, header);
        this.subtheme = response.data;

        const newCacheVersion = new Date().getTime().toString();
        localStorage.setItem("cacheVersion", newCacheVersion);

        this.getSubThemeDetails();

        this.snackbarMessage = `Le champ <strong>${this.keyTextMap[field]}</strong> a été mis à jour`;
        this.snackbarColor = "success";
        this.snackbar = true;
      } catch (error) {
        console.error(error);
        this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du champ <strong>${this.keyTextMap[field]}</strong>`;
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },

    formatDate(date) {
      const dateObject = new Date(date);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    },

    openCommentUpdateDialog(comment) {
      this.dialogTitle = `Modifier le commentaire de ${this.formatDate(
        comment.DAT_Created
      )}`;
      this.fieldToUpdate = "TXT_Comment";
      this.newFieldValue = comment.TXT_Comment;
      this.dialog = true;
      this.currentCommentID = comment.ID_FS_CommentsSubTheme; // Stocker l'ID du commentaire actuel
    },

    openAddCommentDialog() {
      this.newComment = ""; // Reset the new comment field
      this.addCommentDialog = true;
    },

    addComment() {
      const url = `${base_url}${comments_urls.subtheme_all}`;

      const commentData = {
        ID_NDAppUser: this.$store.state.auth.id,
        DAT_Created: new Date().toISOString(),
        TXT_Comment: this.newComment,
        ID_D_SubTheme: this.$route.params.id,
      };

      axios
        .post(url, commentData, header)
        .then(() => {
          this.getSubThemeDetails(); // Fetch subtheme details again to update the comments list

          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);

          this.snackbarMessage = `Votre commentaire a été ajouté avec succès.`;
          this.snackbar = true;

          this.addCommentDialog = false;
        })
        .catch((error) => {
          console.error("Error adding comment:", error);

          this.snackbarMessage = `Une erreur s'est produite lors de l'ajout du commentaire`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    openDeleteDialog(comment) {
      this.commentToDelete = comment;
      this.deleteDialog = true;
    },

    deleteComment() {
      if (this.commentToDelete) {
        const url = `${base_url}${comments_urls.delete_subtheme(
          this.commentToDelete.ID_FS_CommentsSubTheme
        )}`;

        axios
          .delete(url, header)
          .then(() => {
            this.getSubThemeDetails();

            const newCacheVersion = new Date().getTime().toString();
            localStorage.setItem("cacheVersion", newCacheVersion);

            this.snackbarMessage = `Votre commentaire a été supprimé.`;
            this.snackbar = true;

            this.deleteDialog = false;
          })
          .catch((error) => {
            console.error("Error deleting comment:", error);

            this.snackbarMessage = `Erreur lors de la suppression du commentaire`;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
      }
    },
  },

  watch: {
    dialog(newVal) {
      if (newVal && this.fieldToUpdate === "Modules") {
        this.getModules();
      }
    },
    PopupModules: {
      handler(newItems) {
        newItems.forEach((item) => {
          if (item.selected) {
            this.selectedItems.add(item.ID_D_Module);
          } else {
            this.selectedItems.delete(item.ID_D_Module);
          }
        });
      },
      deep: true,
    },
    options: {
      handler() {
        this.getModules();
      },
      deep: true,
    },
  },

  computed: {
    reversedComments() {
      return [...(this.subtheme.comments || [])].reverse();
    },
    formattedModules() {
      if (!this.search) {
        return this.Modules.map((module) => ({
          title:
            module.TXT_Module.length > 75
              ? module.TXT_Module.substring(0, 75) + "..."
              : module.TXT_Module,
          value: module.ID_D_Module,
        }));
      }
      const searchTerms = this.search
        .toLowerCase()
        .split(" ")
        .filter((word) => word.length > 0);
      return this.Modules.filter((module) => {
        const moduleLowerCase = module.TXT_Module.toLowerCase();
        return searchTerms.every((term) => moduleLowerCase.includes(term));
      }).map((module) => ({
        title:
          module.TXT_Module.length > 75
            ? module.TXT_Module.substring(0, 75) + "..."
            : module.TXT_Module,
        value: module.ID_D_Module,
      }));
    },
  },

  created() {
    this.getSubThemeDetails();
    this.getModules();
  },

  mounted() {
    document.title = "Sous-Thème - Détails";
  },
};
</script>

<style lang="scss">
.details .data-table .v-table .v-data-table__td {
  width: 33%;
}
</style>
