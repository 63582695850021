<template>
  <div>
    <NavBar></NavBar>
    <h1 class="text-h2 mt-6 text-center">DashBoard</h1>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";

export default {
  mixins: [authExpirationMixin],
  name: "DashBoard",
  components: {
    NavBar,
  },

  mounted() {
    document.title = "Accueil";
  },
};
</script>

<style lang="scss"></style>
