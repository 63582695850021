<template>
  <div>
    <NavBarGuest v-if="!isUserStaff"></NavBarGuest>
    <div class="details power-bi">
      <div class="title-block pt-4">
        <button class="return" onclick="history.back()">
          <v-icon>mdi-keyboard-return</v-icon>
        </button>
        <h1 class="text-h2 mb-4 text-center">Rapport de suivi de Formation</h1>
      </div>
      <div class="button-container">
        <router-link class="btn-link" to="/reports/trainee">
          <v-btn large color="primary" class="btn ma-2 btn-trainee">
            Rapports Apprenant·e
          </v-btn>
        </router-link>
        <router-link class="btn-link" to="/reports/management">
          <v-btn large color="secondary" class="btn ma-2 btn-management">
            Rapports Management
          </v-btn>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import NavBarGuest from "@/components/NavBarGuest.vue";
import authExpirationMixin from "@/mixins/isLogged.js";

export default {
  mixins: [authExpirationMixin],
  name: "ReportsPBI",
  components: {
    NavBarGuest,
  },

  computed: {
    isUserStaff() {
      return localStorage.getItem("staff") === "true";
    },
  },
};
</script>

<style lang="scss">
.power-bi {
  height: 100vh;
  overflow: hidden;
  position: relative;

  .reportClass {
    height: 100%;
  }

  .return-reports {
    position: absolute;
    top: 25px;
    left: 25px;
    color: #000;
    background: #fff;
    border: 1px solid #000;
    height: max-content;
    border-radius: 5px;
    padding: 5px;
  }
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 76px);
    margin-top: -76px;
    gap: 50px;
    .btn {
      width: 500px;
      height: 200px;
      font-weight: 600;
      font-size: 20px;
      &-trainee {
        animation: fadeIn 0.5s ease-out forwards;
        animation-delay: 0.5s;
        opacity: 0;
      }
      &-management {
        animation: fadeIn 0.5s ease-out forwards;
        animation-delay: 0.7s;
        opacity: 0;
      }

      &-link {
        text-decoration: none;
      }
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .loading-message {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
