<template>
  <div>
    <NavBarGuest v-if="!isUserStaff"></NavBarGuest>
    <NavBar v-else></NavBar>
    <h1 class="text-h2 my-8 text-center">Mon Compte</h1>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="10" md="8">
          <v-card class="pa-4 profile-card">
            <v-card-title>Mon Profil</v-card-title>
            <v-card-text>
              <v-list-item-group>
                <v-list-item>
                  <div>
                    <v-list-item-title class="font-weight-bold"
                      >Nom d'utilisateur:</v-list-item-title
                    >
                    {{ userDetails.username }}
                  </div>
                </v-list-item>
                <v-list-item class="mt-4">
                  <div>
                    <v-list-item-title class="font-weight-bold"
                      >Email:</v-list-item-title
                    >
                    {{ userDetails.email }}
                  </div>
                </v-list-item>
                <v-list-item class="mt-4">
                  <div>
                    <v-list-item-title class="font-weight-bold"
                      >Membre depuis:</v-list-item-title
                    >
                    {{ formattedDateJoined }}
                  </div>
                </v-list-item>
              </v-list-item-group>
            </v-card-text>
            <v-card-actions>
              <p>
                Modification du mot de passe ?
                <router-link to="/change-password">Cliquez ici !</router-link>
              </p>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import NavBarGuest from "@/components/NavBarGuest.vue";
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import { base_url, user_urls, header } from "@/API/config.js";

export default {
  name: "AccountProfile",
  components: {
    NavBarGuest,
    NavBar,
  },
  mixins: [authExpirationMixin],
  data() {
    return {
      userDetails: {},
    };
  },
  computed: {
    formattedDateJoined() {
      if (!this.userDetails.date_joined) return "";
      const date = new Date(this.userDetails.date_joined);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    },
    isUserStaff() {
      return localStorage.getItem("staff") === "true";
    },
  },

  mounted() {
    document.title = "Mon compte";
    this.fetchUserDetails();
  },

  methods: {
    async fetchUserDetails() {
      const userId = localStorage.getItem("id");
      if (userId) {
        try {
          const response = await this.getUserDetails(userId);
          this.userDetails = response.data;
        } catch (error) {
          console.error(error);
        }
      }
    },
    getUserDetails(id) {
      const url = `${base_url}${user_urls.get_details(id)}`;
      return axios.get(url, header);
    },
  },
};
</script>

<style>
.profile-card {
  max-width: 800px;
  min-width: 500px;
  margin: auto;
}
.font-weight-bold {
  font-weight: bold;
}
/* Ajoutez ici d'autres styles personnalisés si nécessaire */
</style>
