import { createRouter, createWebHistory } from "vue-router";
import SignIn from "@/views/SigninView.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import InitPassword from "@/views/InitPassword.vue";

import EditPassword from "@/views/EditPassword.vue";
import ChangePassword from "@/views/ChangePassword.vue";

import DashBoard from "@/views/DashBoard.vue";
import GuestMain from "@/views/GuestMain.vue";

import AccountProfile from "@/views/AccountProfile.vue";

import NotFoundComponent from "@/views/404Page.vue";

import TraineesMain from "@/views/TraineesMain.vue";
import TraineesSlug from "@/views/TraineesSlug.vue";

import ManagementsMain from "@/views/ManagementsMain.vue";
import ManagementsSlug from "@/views/ManagementsSlug.vue";

import BusinessUnitsMain from "@/views/BusinessUnitsMain.vue";
import BusinessUnitSlug from "@/views/BusinessUnitSlug.vue";

import ParcoursMain from "@/views/ParcoursMain.vue";
import ParcoursSlug from "@/views/ParcoursSlug.vue";

import ThemesMain from "@/views/ThemesMain.vue";
import ThemesSlug from "@/views/ThemesSlug.vue";

import SubThemesMain from "@/views/SubThemesMain.vue";
import SubThemesSlug from "@/views/SubThemesSlug.vue";

import ModulesMain from "@/views/ModulesMain.vue";
import ModulesSlug from "@/views/ModulesSlug.vue";

import QuestionsMain from "@/views/QuestionsMain.vue";
import QuestionsSlug from "@/views/QuestionsSlug.vue";

import AddPage from "@/views/AddPage.vue";

import ContenuMain from "@/views/ContenuMain.vue";

import ReportsPBI from "@/views/ReportsPBI.vue";
import ReportsInternes from "@/views/ReportsInternes.vue";
import ReportsStaff from "@/views/ReportsStaff.vue";
import ReportsManagement from "@/views/ReportsManagement.vue";
import ReportsTrainee from "@/views/ReportsTrainee.vue";

const isAuthenticated = () => {
  return localStorage.getItem("id") && localStorage.getItem("token");
};

const isStaff = () => {
  return localStorage.getItem("staff") === "true";
};

const roleChecker = (to, from, next) => {
  if (isAuthenticated() && isStaff() === true) {
    next();
  } else if (isAuthenticated() && isStaff() === false) {
    next({ name: "reports" });
  } else {
    next({ name: "signin" });
  }
};

const guest = (to, from, next) => {
  if (isAuthenticated()) {
    next();
  } else {
    next({ name: "signin" });
  }
};

const routes = [
  {
    path: "/",
    name: "signin",
    component: SignIn,
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        next({ name: "dashboard" });
      } else {
        next();
      }
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword,
  },
  {
    path: "/init-password",
    name: "init-password",
    component: InitPassword,
  },
  {
    path: "/edit-password",
    name: "edit-password",
    component: EditPassword,
  },
  {
    path: "/change-password",
    name: "change-password",
    component: ChangePassword,
    beforeEnter: roleChecker,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashBoard,
    beforeEnter: roleChecker,
  },

  {
    path: "/guest",
    name: "guest",
    component: GuestMain,
    beforeEnter: guest,
  },

  {
    path: "/account",
    name: "account-profile",
    component: AccountProfile,
    beforeEnter: guest,
  },

  {
    path: "/:catchAll(.*)",
    name: "not-found",
    component: NotFoundComponent,
  },

  {
    path: "/trainees",
    name: "trainees",
    component: TraineesMain,
    beforeEnter: roleChecker,
  },
  {
    path: "/trainees/:id",
    name: "traineesSlug",
    component: TraineesSlug,
    beforeEnter: roleChecker,
  },

  {
    path: "/managements",
    name: "managements",
    component: ManagementsMain,
    beforeEnter: roleChecker,
  },
  {
    path: "/managements/:id",
    name: "management",
    component: ManagementsSlug,
    beforeEnter: roleChecker,
  },

  {
    path: "/business-units",
    name: "businessunits",
    component: BusinessUnitsMain,
    beforeEnter: roleChecker,
  },
  {
    path: "/business-units/:id",
    name: "businessunitSlug",
    component: BusinessUnitSlug,
    beforeEnter: roleChecker,
  },

  {
    path: "/parcours",
    name: "parcours",
    component: ParcoursMain,
    beforeEnter: roleChecker,
  },
  {
    path: "/parcours/:id",
    name: "parcoursSlug",
    component: ParcoursSlug,
    beforeEnter: roleChecker,
  },

  {
    path: "/themes",
    name: "themes",
    component: ThemesMain,
    beforeEnter: roleChecker,
  },
  {
    path: "/themes/:id",
    name: "themesSlug",
    component: ThemesSlug,
    beforeEnter: roleChecker,
  },

  {
    path: "/subthemes",
    name: "subthemes",
    component: SubThemesMain,
    beforeEnter: roleChecker,
  },
  {
    path: "/subthemes/:id",
    name: "subthemesSlug",
    component: SubThemesSlug,
    beforeEnter: roleChecker,
  },

  {
    path: "/modules",
    name: "modules",
    component: ModulesMain,
    beforeEnter: roleChecker,
  },
  {
    path: "/modules/:id",
    name: "modulesSlug",
    component: ModulesSlug,
    beforeEnter: roleChecker,
  },

  {
    path: "/questions",
    name: "questions",
    component: QuestionsMain,
    beforeEnter: roleChecker,
  },
  {
    path: "/questions/:id",
    name: "questionsSlug",
    component: QuestionsSlug,
    beforeEnter: roleChecker,
  },

  {
    path: "/add/",
    name: "addpage",
    component: AddPage,
    beforeEnter: roleChecker,
  },

  {
    path: "/contenu",
    name: "contenu",
    component: ContenuMain,
    beforeEnter: roleChecker,
  },

  {
    path: "/reports",
    name: "reports",
    component: ReportsPBI,
    beforeEnter: guest,
  },
  {
    path: "/internal-reports",
    name: "reports-internes",
    component: ReportsInternes,
    beforeEnter: roleChecker,
  },
  {
    path: "/reports/staff",
    name: "reports-staff",
    component: ReportsStaff,
    beforeEnter: roleChecker,
  },
  {
    path: "/reports/management",
    name: "reports-management",
    component: ReportsManagement,
    beforeEnter: guest,
  },
  {
    path: "/reports/trainee",
    name: "reports-trainee",
    component: ReportsTrainee,
    beforeEnter: guest,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
