<template>
  <div>
    <v-form ref="form" v-model="valid">
      <v-textarea
        rows="1"
        auto-grow
        v-model="form.TXT_Theme"
        label="Thème"
        variant="solo"
        class="form-input required"
        :rules="[rules.required]"
      />
      <v-textarea
        rows="3"
        auto-grow
        v-model="form.TXT_Description"
        label="Description"
        variant="solo"
        class="form-input required"
        :rules="[rules.required]"
      />
      <v-textarea
        rows="3"
        auto-grow
        v-model="form.TXT_LearningObjectif"
        label="Objectifs Pédagogiques"
        variant="solo"
        class="form-input required"
        :rules="[rules.required]"
      />
      <v-btn
        class="px-16 py-6 d-flex rounded-lg mb-2 elevation-4"
        color="primary"
        :loading="loading"
        :disabled="!valid"
        @click="submitForm"
      >
        Ajouter le Thème
      </v-btn>
    </v-form>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { base_url, themes_urls, header } from "@/API/config.js";

export default {
  data() {
    return {
      valid: false,
      loading: false,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      form: {
        TXT_Theme: "",
        TXT_Description: "",
        TXT_LearningObjectif: "",
        TXT_CreatedBy: this.$store.state.auth.id,
        TXT_LastEditedBy: this.$store.state.auth.id,
      },
      rules: {
        required: (value) => !!value || "Champ requis.",
      },
    };
  },
  methods: {
    async submitForm() {
      const url = `${base_url}${themes_urls.get_all}`;

      const timestamp = new Date().toISOString().slice(0, -1) + "000Z";
      this.form.DAT_Created = timestamp;
      this.form.DAT_LastEdited = timestamp;

      this.loading = true;

      try {
        await axios.post(url, this.form, header);
        this.resetForm();
        this.snackbarMessage = `Un nouveau <strong>Thème</strong> a été ajouté avec succès`;
        this.snackbarColor = "success";
      } catch (error) {
        console.error(error);
        this.snackbarMessage = `Une erreur s'est produite lors de l'ajout du <strong>Thème</strong>`;
        this.snackbarColor = "error";
      } finally {
        this.loading = false;
        this.snackbar = true;
      }
    },

    resetForm() {
      this.form = {
        TXT_Theme: "",
        TXT_Description: "",
        TXT_LearningObjectif: "",
        TXT_CreatedBy: this.$store.state.auth.id,
        TXT_LastEditedBy: this.$store.state.auth.id,
      };
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>
