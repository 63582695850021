<template>
  <div class="addpage">
    <NavBar></NavBar>
    <h1 class="text-center mt-6 text-h2">{{ tabTitles[tab] }}</h1>
    <div class="v-tabs d-flex flex-column align-center">
      <v-tabs v-model="tab" dark slider-color="primary" class="my-10">
        <v-tab value="themes">Thèmes</v-tab>
        <v-tab value="subthemes">Sous-Thèmes</v-tab>
        <v-tab value="modules">Modules</v-tab>
        <v-tab value="questions">Questions</v-tab>
      </v-tabs>

      <v-window v-model="tab" class="w-100 tabs-fields">
        <v-window-item value="themes">
          <ThemeForm />
        </v-window-item>
        <v-window-item value="subthemes">
          <SubthemeForm />
        </v-window-item>
        <v-window-item value="modules">
          <ModuleForm />
        </v-window-item>
        <v-window-item value="questions">
          <QuestionForm />
        </v-window-item>
      </v-window>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";

import ThemeForm from "../components/ThemeForm.vue";
import SubthemeForm from "../components/SubthemeForm.vue";
import ModuleForm from "../components/ModuleForm.vue";
import QuestionForm from "../components/QuestionForm.vue";

export default {
  mixins: [authExpirationMixin],
  name: "AddPage",
  components: {
    NavBar,
    ThemeForm,
    SubthemeForm,
    ModuleForm,
    QuestionForm,
  },
  data() {
    return {
      tab: null,
      tabTitles: {
        themes: "Nouveau Thème",
        subthemes: "Nouveau Sous-Thème",
        modules: "Nouveau Module",
        questions: "Nouvelle Question",
      },
    };
  },

  mounted() {
    document.title = "Ajout d'éléments";
  },
};
</script>

<style lang="scss">
.tabs-fields {
  max-width: 1200px;
}

.addpage {
  .tabs-fields {
    .v-input {
      max-width: 600px;
      min-width: 580px;
    }
    .form-input {
      .v-field {
        background-color: #454547;
      }
      &.required {
        .v-field {
          background-color: #3e424e;
        }
      }
    }
    .v-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      .v-input__details {
        padding-top: 0;
        padding-bottom: 5px;
      }
    }
    .gap-20 {
      gap: 20px;
    }
  }
  .btn-data-table {
    max-width: 600px;
    min-width: 580px;
    min-height: 60px;
    background-color: #454547;
    transition: 0.4s;
  }
}
</style>
