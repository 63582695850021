<template>
  <div class="details power-bi">
    <v-btn
      class="return-reports"
      onclick="history.back()"
      color="white"
      outlined
    >
      Exit
    </v-btn>
    <PowerBIReportEmbed
      v-if="!loading && !unauthorized && !error"
      :embedConfig="embedConfig"
      :class="{ reportClass: true }"
      :phasedEmbedding="false"
      :eventHandlers="eventHandlers"
    ></PowerBIReportEmbed>
    <div v-if="unauthorized" class="loading-message text-center text-h5">
      {{ userErrorMessage }}
    </div>
    <div
      v-if="error && !unauthorized"
      class="loading-message text-center text-h6"
    >
      {{ userErrorMessage || "Erreur lors du chargement du rapport PBI..." }}
    </div>
  </div>
</template>

<script>
import authExpirationMixin from "@/mixins/isLogged.js";
import { PowerBIReportEmbed } from "powerbi-client-vue-js";
import { models } from "powerbi-client";
import axios from "axios";
import {
  base_url,
  user_urls,
  trainees_urls,
  pbi_urls,
  header,
} from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "DashBoard",
  components: {
    PowerBIReportEmbed,
  },

  data() {
    return {
      embedConfig: {
        type: "",
        embedUrl: "",
        id: "",
        accessToken: "",
        tokenType: "",
        settings: {},
      },
      eventHandlers: new Map([
        ["loaded", () => console.log("Report loaded")],
        ["rendered", () => console.log("Report rendered")],
        ["error", (event) => console.log(event.detail)],
      ]),
      loading: true,
      error: null,
      unauthorized: false,
      userErrorMessage: null,
      reportId: process.env.VUE_APP_INTERNAL_REPORT_ID,
      workspaceId: process.env.VUE_APP_INTERNAL_WORKSPACE_ID,
      requestBody: {
        accessLevel: "View",
      },
    };
  },

  methods: {
    getUserDetails(id) {
      const url = `${base_url}${user_urls.get_details(id)}`;
      return axios.get(url, header).catch((error) => {
        this.userErrorMessage = "Aucun utilisateur avec cet ID n'a été trouvé.";
        throw error;
      });
    },

    getTraineeDetails(traineeId) {
      const url = `${base_url}${trainees_urls.get_details(traineeId)}`;
      return axios.get(url, header).catch((error) => {
        this.userErrorMessage = "Aucun trainee avec cet ID n'a été trouvé.";
        throw error;
      });
    },

    async getPowerBIToken() {
      this.error = null;
      this.userErrorMessage = null;

      try {
        const urlParams = new URLSearchParams(window.location.search);
        const traineeId = urlParams.get("trainee");
        const managerId = this.$store.state.auth.id; // ID du manager connecté

        let userId;

        if (traineeId) {
          const traineeResponse = await this.getTraineeDetails(traineeId);
          const relatedManagers = traineeResponse.data.RelatedManagers;
          const isAuthorized = relatedManagers.some(
            (manager) => manager.ID_NDAppUser === managerId.toString()
          );

          if (!isAuthorized) {
            this.unauthorized = true;
            this.userErrorMessage =
              "Vous n'êtes pas autorisé à voir ce rapport.";
            return;
          }

          userId = traineeResponse.data.ID_NDAppUser;
        } else {
          userId = managerId;
        }

        await this.getUserDetails(userId);

        const requestPayload = {
          workspaceId: this.workspaceId,
          reportId: this.reportId,
          request_body: this.requestBody,
        };

        const url = `${base_url}${pbi_urls.get_token}`;
        const response = await axios.post(url, requestPayload, header);
        const data = response.data;

        this.embedConfig = {
          type: "report",
          accessToken: data.embedToken,
          id: this.reportId,
          embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${this.reportId}&groupId=${this.workspaceId}`,
          tokenType: models.TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false,
              },
            },
            background: models.BackgroundType.Transparent,
          },
        };
      } catch (error) {
        if (!this.unauthorized) {
          console.error("Error getting PowerBI Report :", error);
          this.error = error;
        }
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    document.title = "Rapports Power BI";
    this.getPowerBIToken();
  },
};
</script>

<style lang="scss"></style>
