<template>
  <div>
    <v-form ref="form" v-model="valid">
      <v-btn @click="openSubThemesDialog" class="btn-data-table mb-5">
        Sélectionner un Sous-Thème
      </v-btn>
      <v-chip
        v-if="selectedSubTheme"
        class="mb-3"
        @click:close="removeSubTheme"
      >
        {{ selectedSubTheme.TXT_SubTheme }}
      </v-chip>

      <v-dialog
        v-model="subThemesDialog"
        transition="dialog-top-transition"
        persistent
        min-width="800"
      >
        <v-card class="rounded-lg pa-4" color="dialog">
          <v-card-title class="headline"
            >Sélectionner un Sous-Thème</v-card-title
          >
          <v-card-text>
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Rechercher"
              clearable
              single-line
              hide-details
              @keyup.enter="getSubThemes(true)"
              @click:prepend-inner="getSubThemes(true)"
              @click:clear="resetSearch"
              density="compact"
              bg-color="grey-darken-3"
              color="primary"
            ></v-text-field>
            <v-data-table-server
              v-model:page="currentPage"
              :page="currentPage"
              :headers="headers"
              :items="SubThemes"
              v-model:options="options"
              items-per-page-text="Nombre d'élements par page."
              no-data-text="Aucune donnée"
              :items-length="totalItems"
              :loading="isLoading"
              @update:options="updateOptions"
              density="compact"
            >
              <template v-slot:[`item.select`]="{ item }">
                <v-checkbox
                  class="d-flex justify-center ma-0 pa-0"
                  v-model="selectedSubThemeId"
                  :value="item.ID_D_SubTheme"
                  @change="selectSubTheme(item)"
                  density="compact"
                ></v-checkbox>
              </template>
            </v-data-table-server>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="subThemesDialog = false"
              >Fermer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-textarea
        rows="1"
        auto-grow
        v-model="form.TXT_Module"
        label="Module"
        variant="solo"
        class="form-input required"
        :rules="[rules.required]"
      />
      <v-textarea
        rows="3"
        auto-grow
        v-model="form.TXT_Description"
        label="Description"
        variant="solo"
        class="form-input required"
        :rules="[rules.required]"
      />
      <v-textarea
        rows="3"
        auto-grow
        v-model="form.TXT_LearningObjectif"
        label="Objectifs Pédagogiques"
        variant="solo"
        class="form-input required"
        :rules="[rules.required]"
      />
      <v-btn
        class="px-16 py-6 d-flex rounded-lg mb-2 elevation-4"
        color="primary"
        :loading="loading"
        :disabled="!valid"
        @click="submitForm"
      >
        Ajouter le Module
      </v-btn>
    </v-form>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import {
  base_url,
  modules_urls,
  subthemes_urls,
  header,
} from "@/API/config.js";

export default {
  data() {
    return {
      search: "",
      valid: false,
      loading: false,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",
      SubThemes: [],
      selectedSubTheme: null,
      selectedSubThemeId: null,
      isLoading: false,
      subThemesDialog: false,
      totalItems: 0,
      headers: [
        {
          title: "Sélectionner",
          value: "select",
          sortable: false,
          width: "100px",
        },
        { title: "Sous-Thème", value: "TXT_SubTheme" },
        { title: "Description", value: "TXT_Description" },
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      currentPage: 1,
      cacheVersion: null,

      form: {
        TXT_Module: "",
        TXT_Description: "",
        TXT_LearningObjectif: "",
        TXT_CreatedBy: this.$store.state.auth.id,
        TXT_LastEditedBy: this.$store.state.auth.id,
      },
      rules: {
        required: (value) => !!value || "Champ requis.",
      },
    };
  },
  methods: {
    async initializeCacheBusting() {
      const cacheVersionKeys = Object.keys(localStorage).filter((key) =>
        key.startsWith("cacheVersion")
      );

      if (cacheVersionKeys.length > 0) {
        const latestCacheVersion = cacheVersionKeys.reduce((latest, key) => {
          const version = parseInt(localStorage.getItem(key));
          return version > latest ? version : latest;
        }, 0);

        return latestCacheVersion.toString();
      } else {
        const response = await axios.get(
          `${base_url}${subthemes_urls.get_all}`,
          header
        );
        const cacheVersion = response.data.results.cache_version;
        localStorage.setItem("cacheVersion", cacheVersion);
        return cacheVersion;
      }
    },

    updateCacheBusting(newCacheVersion) {
      localStorage.setItem(`cacheVersion_${newCacheVersion}`, newCacheVersion);
    },

    async getSubThemes(resetPage = false) {
      if (resetPage) {
        this.currentPage = 1;
        this.options.page = 1;
      }

      this.isLoading = true;
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${subthemes_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${cacheVersion}`;
      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }
      try {
        const response = await axios.get(url, header);
        this.totalItems = response.data.count;
        this.SubThemes = response.data.results.results;

        if (response.data.results.cache_version !== cacheVersion) {
          this.updateCacheBusting(response.data.results.cache_version);
          await this.refreshData();
        }
      } catch (error) {
        console.error("Error fetching sub-themes:", error);
      } finally {
        this.isLoading = false;
      }
    },

    async refreshData() {
      const newCacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${subthemes_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${newCacheVersion}`;
      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }
      try {
        const response = await axios.get(url, header);
        this.totalItems = response.data.count;
        this.SubThemes = response.data.results.results;
      } catch (error) {
        console.error("Error refreshing sub-themes data:", error);
      }
    },

    resetSearch() {
      this.search = "";
      this.getSubThemes(true);
    },

    updateOptions(newOptions) {
      this.options = newOptions;
      this.getSubThemes();
    },

    selectSubTheme(subTheme) {
      this.selectedSubTheme = subTheme;
      this.subThemesDialog = false;
    },

    removeSubTheme() {
      this.selectedSubTheme = null;
      this.selectedSubThemeId = null;
    },

    openSubThemesDialog() {
      this.subThemesDialog = true;
      this.getSubThemes();
    },

    async submitForm() {
      const url = `${base_url}${modules_urls.get_all}`;

      const timestamp = new Date().toISOString().slice(0, -1) + "000Z";
      this.form.DAT_Created = timestamp;
      this.form.DAT_LastEdited = timestamp;
      this.form.SubThemes = this.selectedSubTheme
        ? this.selectedSubTheme.ID_D_SubTheme
        : null;

      this.loading = true;

      try {
        await axios.post(url, this.form, header);
        this.resetForm();
        this.snackbarMessage = `Un nouveau <strong>Module</strong> a été ajouté avec succès`;
        this.snackbarColor = "success";
      } catch (error) {
        console.error(error);
        this.snackbarMessage = `Une erreur s'est produite lors de l'ajout du <strong>Module</strong>`;
        this.snackbarColor = "error";
      } finally {
        this.loading = false;
        this.snackbar = true;
      }
    },

    resetForm() {
      this.form = {
        TXT_Module: "",
        TXT_Description: "",
        TXT_LearningObjectif: "",
        TXT_CreatedBy: this.$store.state.auth.id,
        TXT_LastEditedBy: this.$store.state.auth.id,
      };
      this.selectedSubTheme = null;
      this.selectedSubThemeId = null;
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },

  created() {
    this.getSubThemes();
  },
};
</script>
