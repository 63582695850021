<template>
  <v-menu
    class="notification"
    v-model="menu"
    :close-on-content-click="false"
    location="end"
  >
    <template v-slot:activator="{ props }">
      <v-btn variant="text" class="notificationBellButton" v-bind="props">
        <v-badge
          :content="alerts.length"
          class="notificationBellBadge"
          color="error"
        >
          <v-icon class="notificationBell" size="x-large"
            >mdi-bell-ring-outline</v-icon
          >
        </v-badge>
      </v-btn>
    </template>
    <v-card min-width="300">
      <v-spacer></v-spacer>
      <v-list>
        <v-list-item v-for="(alert, index) in alerts" :key="index">
          {{ alert.alert }} : {{ alert.alert_count }}
          <v-btn
            class="ml-6"
            color="primary"
            variant="text"
            @click="$emit('change-tab', alert.ID_V_API_Alerts)"
            >Corriger</v-btn
          >
        </v-list-item>
      </v-list>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="menu = false" large>OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: ["alerts"],

  data: () => ({
    menu: false,
  }),
};
</script>

<style lang="scss">
.notification {
  .v-list {
    .v-list-item {
      justify-content: end;
    }
  }
}
</style>
