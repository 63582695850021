<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer expand-on-hover rail class="accent">
        <v-list nav class="nav h-100">
          <v-list-item
            :key="nav.index"
            v-for="nav in items"
            link
            :title="nav.title"
            :value="nav.title"
            :prepend-icon="nav.icon"
            :to="nav.link"
            :class="nav.class"
            @click="nav.click && nav.click()"
          />
        </v-list>
      </v-navigation-drawer>
    </v-layout>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "NavBar",
  data() {
    return {
      items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          link: "/dashboard",
        },
        {
          title: "Rapports",
          icon: "mdi-file-chart",
          link: "/reports",
        },
        {
          title: "Rapports internes",
          icon: "mdi-file-chart",
          link: "/internal-reports",
        },
        {
          title: "Apprenant·es",
          icon: "mdi-account-group",
          link: "/trainees",
        },
        {
          title: "Management",
          icon: "mdi-account-tie",
          link: "/managements",
        },
        {
          title: "Business Units",
          icon: "mdi-school",
          link: "/business-units",
        },
        {
          title: "Parcours",
          icon: "mdi-arrow-decision",
          link: "/parcours",
        },
        {
          title: "Themes",
          icon: "mdi-folder-open",
          link: "/themes",
        },
        {
          title: "Sous-thèmes",
          icon: "mdi-folder-table",
          link: "/subthemes",
        },
        {
          title: "Modules",
          icon: "mdi-view-module",
          link: "/modules",
        },
        {
          title: "Questions",
          icon: "mdi-file-question",
          link: "/questions",
        },
        {
          title: "Ajouter",
          icon: "mdi-plus-box",
          link: "/add",
        },

        {
          title: "Contenu",
          icon: "mdi-domain",
          link: "/contenu",
        },
        {
          title: "Profil",
          icon: "mdi-account",
          link: "/account",
          class: "account",
        },
        {
          title: "Déconnexion",
          icon: "mdi-logout",
          click: this.signout,
          link: "/",
          class: "logout",
        },
      ],
    };
  },
  methods: {
    ...mapActions("auth", ["signout"]),
  },
};
</script>

<style lang="scss">
.nav {
  position: relative;
}
.logout {
  position: absolute;
  bottom: 0;
  width: 239px;
}

.account {
  position: absolute;
  bottom: 55px;
  width: 239px;
}
</style>
