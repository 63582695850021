<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer expand-on-hover rail class="accent">
        <v-list nav class="nav h-100">
          <v-list-item
            :key="nav.index"
            v-for="nav in items"
            link
            :title="nav.title"
            :value="nav.title"
            :prepend-icon="nav.icon"
            :to="nav.link"
            :class="nav.class"
            @click="nav.click && nav.click()"
          />
        </v-list>
      </v-navigation-drawer>
    </v-layout>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "NavBar",
  data() {
    return {
      items: [
        {
          title: "Rapports",
          icon: "mdi-file-chart",
          link: "/reports",
        },
        {
          title: "Profil",
          icon: "mdi-account",
          link: "/account",
          class: "account",
        },
        {
          title: "Déconnexion",
          icon: "mdi-logout",
          click: this.signout,
          link: "/",
          class: "logout",
        },
      ],
    };
  },
  methods: {
    ...mapActions("auth", ["signout"]),
  },
};
</script>

<style scoped lang="scss">
.nav {
  position: relative;
}
.logout {
  position: absolute;
  bottom: 0;
  width: 239px;
}
</style>
