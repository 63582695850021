export default {
  created() {
    // Reusable hook in components
    const expirationDate = this.$store.state.auth.expirationDate;
    if (expirationDate < new Date()) {
      // Checks if expiration date is less than current date
      this.$store.commit("auth/clearAuthData"); // Deletes user authentication data
      this.$router.push("/");
    } else {
      // Continue
    }
  },
};
