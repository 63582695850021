<template>
  <div class="parcours">
    <NavBar></NavBar>
    <h1 class="text-h2 my-8 text-center">Parcours</h1>
    <div class="mx-16 pb-10">
      <v-card class="data-table">
        <v-card-title>
          <v-text-field
            class="min-100"
            v-model="search"
            clearable
            append-icon="mdi-magnify"
            label="Rechercher..."
            single-line
            color="primary"
            @keyup.enter="getTrainingblocs(true)"
            @click:append="getTrainingblocs(true)"
            @click:clear="resetSearch"
          ></v-text-field>
        </v-card-title>
        <v-data-table-server
          v-model:page="currentPage"
          :headers="headers"
          :show-current-page="true"
          items-per-page-text="Nombre d'élements par page."
          :page="currentPage"
          :items-length="totalTrainingBlocs"
          :loading="loading"
          @update:options="options = $event"
          :items="trainingblocs"
        >
          <template v-slot:[`item.TXT_TrainingBlocName`]="{ item }">
            <router-link :to="`/parcours/${item.ID_D_TrainingBloc}`">{{
              item.TXT_TrainingBlocName
            }}</router-link>
          </template>
          <template v-slot:[`item.TXT_TrainingBlocObjectives`]="{ item }">
            <div class="objectives">
              <template v-if="item.TXT_TargetType !== 'Aucun'">
                {{
                  item.NUM_TargetDurationMinutes &&
                  "Temps : " +
                    convertMinutesToHours(item.NUM_TargetDurationMinutes)
                }}
                {{
                  item.NUM_TargetScore &&
                  "Score : " + item.NUM_TargetScore + " %"
                }}
              </template>
            </div>
          </template>
        </v-data-table-server>
      </v-card>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import { base_url, trainingblocs_urls, header } from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "TrainingBlocsMain",
  components: {
    NavBar,
  },
  data() {
    return {
      search: "",
      sortBy: [],
      loading: false,
      currentPage: 1,
      itemsPerPage: 10,
      totalTrainingBlocs: 0,
      options: {},
      trainingblocs: [],
      selectedColumn: null,
      headers: [
        {
          title: "Nom du parcours",
          value: "TXT_TrainingBlocName",
          key: "TXT_TrainingBlocName",
          sortable: false,
        },
        {
          title: "Objectif",
          value: "TXT_TrainingBlocObjectives",
          key: "TXT_TrainingBlocObjectives",
          sortable: false,
        },
        {
          title: "Groupe Code",
          value: "TXT_NDContentCode",
          key: "TXT_NDContentCode",
          sortable: false,
        },
        {
          title: "ID Drillster",
          value: "TXT_LMSTrainingBlocID",
          key: "TXT_LMSTrainingBlocID",
          sortable: false,
        },
      ],
    };
  },

  computed: {},
  watch: {
    options: {
      handler() {
        this.getTrainingblocs();
      },
      deep: true,
    },
  },

  methods: {
    async initializeCacheBusting() {
      let cacheVersion = localStorage.getItem("cacheVersion");
      if (!cacheVersion) {
        const response = await axios.get(
          `${base_url}${trainingblocs_urls.get_all}`,
          header
        );
        cacheVersion = response.data.results.cache_version;
        localStorage.setItem("cacheVersion", cacheVersion);
      }
      return cacheVersion;
    },

    updateCacheBusting(newCacheVersion) {
      localStorage.setItem("cacheVersion", newCacheVersion);
    },

    async getTrainingblocs(resetPage = false) {
      if (resetPage) {
        this.currentPage = 1;
        this.options.page = 1;
      }

      this.loading = true;
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${trainingblocs_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${cacheVersion}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        this.totalTrainingBlocs = response.data.count;
        this.trainingblocs = response.data.results.results.map(
          (trainingbloc) => ({
            ...trainingbloc,
            TXT_TargetType: trainingbloc.TXT_TargetType || "Test Objectif", // Adjusted to handle null values
          })
        );
      } catch (error) {
        console.error("Error fetching Trainingblocs:", error);
      } finally {
        this.loading = false;
      }

      this.checkForUpdates();
    },

    async checkForUpdates() {
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${
        trainingblocs_urls.get_all
      }?page=${page}&page_size=${itemsPerPage}&timestamp=${new Date().getTime()}&cacheVersion=${cacheVersion}`;

      try {
        const response = await axios.get(url, header);
        if (
          response.data.results.cache_version !==
          localStorage.getItem("cacheVersion")
        ) {
          this.updateCacheBusting(response.data.results.cache_version);
          await this.getTrainingblocs();
        }
      } catch (error) {
        console.error("Error checking for updates:", error);
      }
    },

    resetSearch() {
      this.search = "";
      this.getTrainingblocs(true);
    },

    convertMinutesToHours(minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours} heures ${
        remainingMinutes > 0 ? remainingMinutes + " minutes" : ""
      }`;
    },
  },

  mounted() {
    this.getTrainingblocs();
  },
};
</script>

<style lang="scss">
.parcours {
  .data-table {
    .v-table {
      .v-data-table__td {
        width: 375px;

        //3e colonnes

        &:nth-child(3) {
          width: 150px;
        }
      }
    }
  }
}
</style>
