<template>
  <div class="themes">
    <NavBar></NavBar>
    <h1 class="text-h2 my-8 text-center">Themes</h1>
    <div class="mx-16 pb-10">
      <v-card class="data-table">
        <v-card-title>
          <v-text-field
            class="min-100"
            v-model="search"
            clearable
            append-icon="mdi-magnify"
            label="Rechercher..."
            single-line
            color="primary"
            @keyup.enter="getThemes(true)"
            @click:append="getThemes(true)"
            @click:clear="resetSearch"
          ></v-text-field>
        </v-card-title>
        <v-data-table-server
          v-model:page="currentPage"
          :show-current-page="true"
          items-per-page-text="Nombre d'élements par page."
          :headers="headers"
          :page="currentPage"
          :items-length="totalThemes"
          :items="themes"
          no-data-text="Aucune donnée"
          :loading="loading"
          @update:options="options = $event"
        >
          <template v-slot:[`item.TXT_Theme`]="{ item }">
            <router-link :to="`/themes/${item.ID_D_Theme}`">{{
              item.TXT_Theme
            }}</router-link>
          </template>

          <template v-slot:[`item.Comments`]="{ item }">
            <div>
              <div
                v-if="item.Comments && item.Comments.length > 0"
                :class="{ 'comments-datatable': item.Comments.length > 0 }"
              >
                {{ item.Comments.length }}
                <v-tooltip
                  class="tooltip-comments"
                  activator="parent"
                  location="end"
                >
                  <template #default>
                    <ul>
                      <li
                        v-for="(
                          comment, index
                        ) in item.Comments.slice().reverse()"
                        :key="index"
                      >
                        {{ comment.TXT_Comment }}
                      </li>
                    </ul>
                  </template>
                </v-tooltip>
              </div>
              <div v-else>N/A</div>
            </div>
          </template>

          <template v-slot:[`item.TXT_Offer`]="{ item }">
            <div>
              <p color="primary" class="tooltip-parents__text">
                {{ item.TXT_Offer ? item.TXT_Offer : "" }}
              </p>
            </div>
          </template>

          <template v-slot:[`item.ActionsToPerform`]="{ item }">
            <v-icon
              icon="mdi-pencil"
              size="x-large"
              @click="openDialog(item)"
            ></v-icon>
          </template>

          <template v-slot:[`item.TXT_Ref`]="{ item }">
            {{ item.TXT_Ref ? item.TXT_Ref : "N/A" }}
          </template>
        </v-data-table-server>
      </v-card>
    </div>

    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      max-width="800px"
      min-width="400px"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="currentTheme.TXT_Theme"
            label="Thème"
            color="primary"
          ></v-textarea>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="currentTheme.TXT_Description"
            label="Description"
            color="primary"
          ></v-textarea>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="currentTheme.TXT_LearningObjectif"
            label="Objectif pédagogique"
            color="primary"
          ></v-textarea>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="currentTheme.TXT_Offer"
            label="Offre"
            color="primary"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Fermer</v-btn>
          <v-btn color="primary" text @click="updateTheme">Enregistrer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import { base_url, themes_urls, header } from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "ThemesMain",
  components: {
    NavBar,
  },
  data() {
    return {
      loading: false,
      itemsPerPage: 10,
      currentPage: 1,
      totalThemes: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      cacheVersion: null,
      search: "",
      sortBy: [],
      excludedColumns: [
        "ActionsToPerform",
        "Comments",
        "DAT_Created_Display",
        "DAT_LastEdited",
        "SubThemes",
      ],

      themes: [],
      selectedColumn: null,
      dialog: false,
      dialogTitle: "",
      currentTheme: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      headers: [
        {
          title: "Actions",
          value: "ActionsToPerform",
          key: "ActionsToPerform",
          sortable: false,
        },
        {
          title: "Coms",
          value: "Comments",
          key: "Comments",
          sortable: false,
        },
        {
          title: "Ref",
          value: "TXT_Ref",
          key: "TXT_Ref",
          sortable: false,
        },
        {
          title: "Offer",
          value: "TXT_Offer",
          key: "TXT_Offer",
          sortable: false,
        },
        {
          title: "Thème",
          value: "TXT_Theme",
          key: "TXT_Theme",
          sortable: false,
        },
        {
          title: "Description",
          value: "TXT_Description",
          key: "TXT_Description",
          sortable: false,
        },
        {
          title: "Objectif pédagogique",
          value: "TXT_LearningObjectif",
          key: "TXT_LearningObjectif",
          sortable: false,
        },
        {
          title: "Sous-Thèmes",
          value: "Subthemes_count",
          key: "Subthemes_count",
          sortable: false,
        },
        {
          title: "Création",
          value: "DAT_Created_Display",
          key: "DAT_Created_Display",
          sortable: false,
        },
        {
          title: "Modification",
          value: "DAT_LastEdited",
          key: "DAT_LastEdited",
          sortable: false,
        },
      ],
    };
  },

  computed: {},
  watch: {
    options: {
      handler() {
        this.getThemes();
      },
      deep: true,
    },
  },

  methods: {
    async initializeCacheBusting() {
      const cacheVersionKeys = Object.keys(localStorage).filter((key) =>
        key.startsWith("cacheVersion")
      );

      if (cacheVersionKeys.length > 0) {
        const latestCacheVersion = cacheVersionKeys.reduce((latest, key) => {
          const version = parseInt(localStorage.getItem(key));
          return version > latest ? version : latest;
        }, 0);

        return latestCacheVersion.toString();
      } else {
        const response = await axios.get(
          `${base_url}${themes_urls.get_all}`,
          header
        );
        const cacheVersion = response.data.results.cache_version;
        localStorage.setItem("cacheVersion", cacheVersion);
        return cacheVersion;
      }
    },

    updateCacheBusting(newCacheVersion) {
      localStorage.setItem(`cacheVersion_${newCacheVersion}`, newCacheVersion);
    },

    async getThemes(resetPage = false) {
      if (resetPage) {
        this.currentPage = 1;
        this.options.page = 1;
      }

      this.loading = true;
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${themes_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${cacheVersion}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        this.totalThemes = response.data.count;
        this.themes = response.data.results.results.map((theme) => ({
          ...theme,
          DAT_LastEdited: theme.DAT_LastEdited
            ? this.formatDate(theme.DAT_LastEdited)
            : "N/A",
          DAT_Created: theme.DAT_Created,
          DAT_Created_Display: theme.DAT_Created
            ? this.formatDate(theme.DAT_Created)
            : "N/A",
          TXT_Offer: theme.TXT_Offer ? theme.TXT_Offer : "",
        }));

        if (response.data.results.cache_version !== cacheVersion) {
          this.updateCacheBusting(response.data.results.cache_version);
          await this.refreshData();
        }
      } catch (error) {
        console.error("Error fetching themes:", error);
      } finally {
        this.loading = false;
      }
    },

    async refreshData() {
      const newCacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${themes_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${newCacheVersion}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        this.totalThemes = response.data.count;
        this.themes = response.data.results.results.map((theme) => ({
          ...theme,
          DAT_LastEdited: theme.DAT_LastEdited
            ? this.formatDate(theme.DAT_LastEdited)
            : "N/A",
          DAT_Created: theme.DAT_Created,
          DAT_Created_Display: theme.DAT_Created
            ? this.formatDate(theme.DAT_Created)
            : "N/A",
          TXT_Offer: theme.TXT_Offer ? theme.TXT_Offer : "",
        }));
      } catch (error) {
        console.error("Error refreshing data:", error);
      }
    },

    resetSearch() {
      this.search = "";
      this.getThemes(true);
    },

    openDialog(item) {
      const theme = this.themes.find(
        (themeItem) => themeItem.ID_D_Theme === item.ID_D_Theme
      );

      if (theme) {
        this.dialogTitle = `Modifier ${theme.TXT_Theme}`;
        this.currentTheme = { ...theme };
        this.dialog = true;
      }
    },

    formatDate(date) {
      const dateObject = new Date(date);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    },

    async updateTheme() {
      const themeId = this.currentTheme.ID_D_Theme;
      const url = `${base_url}${themes_urls.update(themeId)}`;

      if (this.currentTheme.TXT_Theme) {
        const updatedTheme = {
          ...this.currentTheme,
          DAT_LastEdited: new Date().toISOString(),
          TXT_LastEditedBy: this.$store.state.auth.id,
        };

        try {
          const response = await axios.put(url, updatedTheme, header);
          this.updateThemeInList(response.data);

          if (response.data && response.data.cache_version) {
            this.updateCacheBusting(response.data.cache_version);
          } else {
            const currentVersion = await this.initializeCacheBusting();
            this.updateCacheBusting(String(parseInt(currentVersion) + 1));
          }

          this.themes.forEach((theme) => {
            if (theme.ID_D_Theme === updatedTheme.ID_D_Theme) {
              theme.DAT_LastEdited = this.formatDate(
                updatedTheme.DAT_LastEdited
              );
            }
          });

          await this.getThemes();
          this.dialog = false;

          this.snackbarMessage = `Le <strong>Thème</strong> a été mis à jour`;
          this.snackbarColor = "success";
          this.snackbar = true;
        } catch (error) {
          console.error(error);
          this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du <strong>Thème</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        }
      }
    },

    updateThemeInList(updatedTheme) {
      const index = this.themes.findIndex(
        (theme) => theme.ID_D_Theme === updatedTheme.ID_D_Theme
      );
      if (index !== -1) {
        this.themes[index] = {
          ...updatedTheme,
          DAT_Created_Display: this.formatDate(updatedTheme.DAT_Created),
        };
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      const offerColumn = Array.from(document.querySelectorAll("th")).find(
        (th) => th.textContent === "Offer"
      );

      if (offerColumn) {
        offerColumn.classList.add("parent-column");
      }
    });

    document.title = "Thèmes";
    this.getThemes();
  },
};
</script>

<style lang="scss">
.themes .data-table .v-table .v-data-table__td:nth-child(-n + 3) {
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
}

.themes .data-table .v-table {
  .v-data-table__td:nth-last-child(-n + 3) {
    width: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    .v-data-table-header__content {
      justify-content: center;
    }
  }
  .v-data-table__th:nth-last-child(-n + 3),
  .v-data-table__th:nth-child(-n + 3) {
    display: flex;
    justify-content: center;
    align-items: center;
    .v-data-table-header__content {
      margin-left: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        white-space: nowrap;
      }
    }
  }
}
</style>
