import axios from "axios";
import { base_url, user_urls } from "@/API/config.js";
import router from "@/router";

const state = {
  // Initial state of the application (defined from localStorage)
  id: localStorage.getItem("id") || null,
  token: localStorage.getItem("token") || null,
  staff: localStorage.getItem("staff") || null,
  expirationDate: localStorage.getItem("expirationDate")
    ? new Date(localStorage.getItem("expirationDate"))
    : null,
};

const mutations = {
  // Mutations that allow to modify the state of the application
  setId(state, id) {
    state.id = id;
    localStorage.setItem("id", id);
  },
  setToken(state, token) {
    state.token = token;
    localStorage.setItem("token", token);
  },
  setExpirationDate(state, expirationDate) {
    state.expirationDate = expirationDate;
    localStorage.setItem("expirationDate", expirationDate.toISOString());
  },
  setRole(state, staff) {
    state.staff = staff;
    localStorage.setItem("staff", staff);
  },
  clearAuthData(state) {
    // Mutation that allows to remove the user authentication data
    state.id = null;
    state.token = null;
    state.expirationDate = null;
    state.staff = null;
    localStorage.removeItem("id");
    localStorage.removeItem("token");
    localStorage.removeItem("expirationDate");
    localStorage.removeItem("staff");
  },
};

const actions = {
  signin({ commit, dispatch }, { username, password }) {
    // Action that allows to connect
    return axios
      .post(`${base_url}${user_urls.user_token}`, {
        username,
        password,
      })
      .then((response) => {
        // If the connection is successful, the authentication data is stored in the state
        const { id, token, staff } = response.data;
        const expirationDate = new Date(Date.now() + 8.64e7); // 24 hours in milliseconds
        commit("setId", id);
        commit("setToken", token);
        commit("setExpirationDate", expirationDate);
        commit("setRole", staff); // Utilise la valeur booléenne
        dispatch("checkAuthExpiration"); // start the expiration check
      });
  },

  signout({ commit }) {
    // Action that allows to disconnect
    commit("clearAuthData");
  },

  checkAuthExpiration({ state, commit }) {
    // function that checks if the user is still authenticated
    setInterval(() => {
      if (state.expirationDate < new Date()) {
        commit("clearAuthData");
        router.push("/signin");
      }
    }, 300000); // 5 minutes in milliseconds
  },
};

const getters = {
  // getters that allow retrieving data from the state
  isAuthenticated: (state) => {
    return !!state.token && !!state.id && state.expirationDate > new Date(); // returns true if the user is authenticated
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
