<template>
  <div class="password-change details">
    <form @submit.prevent="changePassword" class="mt-n8">
      <v-container fluid style="max-width: 1440px">
        <h1 class="text-h2 text-center mb-12">Changer de mot de passe</h1>
        <v-row
          class="justify-center flex-column align-center"
          style="transform: scale(0.85)"
        >
          <v-col cols="6" md="5" class="position-relative">
            <!-- NEW PASSWORD -->
            <v-col cols="12" class="accent px-7 rounded-lg mb-10 elevation-1">
              <v-text-field
                v-model="newPassword"
                :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showNew ? 'text' : 'password'"
                label="Nouveau mot de passe"
                hint="Minimum 8 caractères, une majuscule, un chiffre et un caractère spécial"
                @click:append="showNew = !showNew"
                @input="validatePassword"
                :rules="[rules.required, rules.passwordMatch]"
                variant="underlined"
                required
                :color="isPasswordValid ? 'success' : 'error'"
              ></v-text-field>
            </v-col>

            <!-- CONFIRM PASSWORD -->
            <v-col cols="12" class="accent px-7 rounded-lg elevation-1">
              <v-text-field
                v-model="confirmPassword"
                :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirm ? 'text' : 'password'"
                label="Confirmer le mot de passe"
                @click:append="showConfirm = !showConfirm"
                :rules="[rules.required, rules.passwordMatch]"
                variant="underlined"
                required
                :color="confirmPassword === newPassword ? 'success' : 'error'"
              ></v-text-field>
            </v-col>

            <div v-if="error" class="error-message">{{ error }}</div>

            <!-- SUBMIT -->
            <v-col class="px-7 mt-7 d-flex justify-center">
              <v-btn
                type="submit"
                :disabled="!isFormValid"
                color="primary"
                class="d-flex pa-7 px-11 primary rounded-lg"
                :loading="loading"
              >
                Changer le mot de passe
              </v-btn>
            </v-col>

            <!-- Champ caché pour le token -->
            <input type="hidden" v-model="token" />

            <!-- Validation Rules -->
            <v-card
              :class="{
                'validation-rules hidden': newPassword.length < 1,
                'validation-rules': newPassword.length > 0,
              }"
              elevation="2"
            >
              <v-list>
                <v-list-item
                  v-for="(rule, index) in passwordRules"
                  :key="index"
                >
                  <template v-slot:prepend>
                    <v-icon :color="rule.valid ? 'success' : 'error'">
                      {{ rule.valid ? "mdi-check-circle" : "mdi-close-circle" }}
                    </v-icon>
                  </template>
                  <v-list-item-title
                    :class="{
                      'text-success': rule.valid,
                      'text-error': !rule.valid,
                    }"
                  >
                    {{ rule.text }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </form>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { base_url, user_urls, header } from "@/API/config.js";

export default {
  name: "PasswordChange",
  data: () => ({
    newPassword: "",
    confirmPassword: "",
    error: "",
    showNew: false,
    showConfirm: false,
    token: "",
    snackbar: false,
    snackbarMessage: "",
    snackbarColor: "",
    loading: false,
    passwordRules: [
      { text: "Au moins 8 caractères", valid: false },
      { text: "Au moins une majuscule", valid: false },
      { text: "Au moins un chiffre", valid: false },
      { text: "Au moins un caractère spécial", valid: false },
    ],
    rules: {
      required: (value) => !!value || "Ce champ est requis",
      passwordMatch: () =>
        this.newPassword === this.confirmPassword ||
        "Les mots de passe ne correspondent pas",
    },
  }),
  computed: {
    isPasswordValid() {
      return this.passwordRules.every((rule) => rule.valid);
    },
    isFormValid() {
      return this.isPasswordValid && this.newPassword === this.confirmPassword;
    },
  },
  methods: {
    validatePassword() {
      this.passwordRules[0].valid = this.newPassword.length >= 8;
      this.passwordRules[1].valid = /[A-Z]/.test(this.newPassword);
      this.passwordRules[2].valid = /\d/.test(this.newPassword);
      this.passwordRules[3].valid = /[!@#$%^&*(),.?":{}|<>]/.test(
        this.newPassword
      );
    },
    async changePassword() {
      this.loading = true;

      const cleanedNewPassword = this.newPassword.trim();
      const cleanedConfirmPassword = this.confirmPassword.trim();

      if (cleanedNewPassword !== cleanedConfirmPassword) {
        this.error = "Les mots de passe ne correspondent pas.";
        this.loading = false;
        return;
      }

      const payload = {
        password: cleanedNewPassword,
        token: this.token,
      };

      const url = `${base_url}${user_urls.edit_password}`;

      try {
        await axios.post(url, payload, header);
        this.snackbarMessage = "Mot de passe changé avec succès!";
        this.snackbarColor = "success";
        this.snackbar = true;

        setTimeout(() => {
          this.$router.push("/");
        }, 2000);
      } catch (error) {
        console.error("Error changing password:", error);
        this.snackbarMessage =
          error.response?.data?.error || "Le token a expiré ou est invalide.";
        this.snackbarColor = "error";
        this.snackbar = true;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    document.title = "Changement de mot de passe";
    this.token = this.$route.query.token;
  },
};
</script>

<style lang="scss" scoped>
.password-change {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.position-relative {
  position: relative;
}

.validation-rules {
  position: absolute;
  top: 10px;
  right: -340px;
  width: 330px;
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
  &.hidden {
    opacity: 0;
  }
}

@media (max-width: 1200px) {
  .validation-rules {
    position: static;
    margin-top: 20px;
    width: 100%;
  }
}
</style>
