<template>
  <div>
    <NavBar></NavBar>
    <h1 class="text-h2 my-8 text-center">Détails du Business Unit</h1>
    <div class="mx-16 pb-10">
      <v-card class="data-table">
        <v-card-title> Détails de l'unité commerciale </v-card-title>
        <v-list>
          <v-list-item v-for="(value, key) in businessUnit" :key="key">
            <div>{{ keyTextMap[key] }}{{ value }}</div>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import { base_url, bu_urls, header } from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "BusinessUnitSlug",
  components: {
    NavBar,
  },
  data() {
    return {
      businessUnit: {},
      keyTextMap: {
        ID_D_BusinessUnit: "ID : ",
        TXT_BusinessUnitLevel1: "Niveau 1 : ",
        TXT_BusinessUnitLevel2: "Niveau 2 : ",
        TXT_BusinessUnitLevel3: "Niveau 3 : ",
        TXT_BusinessUnitLevel4: "Niveau 4 : ",
        TXT_BusinessUnitLevel5: "Niveau 5 : ",
      },
    };
  },

  methods: {
    getBusinessUnitDetails() {
      const businessUnitId = this.$route.params.id;
      const url = `${base_url}${bu_urls.get_details(businessUnitId)}`;

      axios
        .get(url, header)
        .then((response) => {
          this.businessUnit = response.data;
        })
        .catch((error) => {
          console.error("Error fetching business unit details:", error);
        });
    },
  },

  created() {
    this.getBusinessUnitDetails(); // Execute the getBusinessUnitDetails method on component creation
  },
};
</script>

<style lang="scss"></style>
