<template>
  <div class="trainees">
    <NavBar></NavBar>
    <h1 class="text-h2 my-8 text-center">Apprenant·es</h1>
    <div class="mx-16 pb-10">
      <v-card class="data-table">
        <v-card-title>
          <v-text-field
            class="min-100"
            v-model="search"
            clearable
            append-icon="mdi-magnify"
            label="Rechercher..."
            single-line
            color="primary"
            @keyup.enter="getTrainees(true)"
            @click:append="getTrainees(true)"
            @click:clear="resetSearch"
          />
        </v-card-title>
        <v-data-table-server
          v-model:page="currentPage"
          :show-current-page="true"
          items-per-page-text="Nombre d'élements par page."
          :headers="headers"
          :page="currentPage"
          :items-length="totalTrainees"
          :loading="loading"
          @update:options="options = $event"
          :items="trainees"
          no-data-text="Aucune donnée"
        >
          <template
            v-for="(property, index) in [
              'TXT_LastName',
              'TXT_FirstName',
              'TXT_Email',
            ]"
            v-slot:[`item.${property}`]="{ item }"
            :key="index"
          >
            <router-link :to="`/trainees/${item.ID_D_Trainee}`">{{
              getItemProperty(item, property)
            }}</router-link>
          </template>
        </v-data-table-server>
      </v-card>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import { base_url, trainees_urls, header } from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "TraineesMain",
  components: {
    NavBar,
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      itemsPerPage: 10,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      cacheVersion: null,
      search: "",
      sortBy: [],
      excludedColumns: ["NUM_NumberOfActiveSubscriptions"],

      trainees: [],
      totalTrainees: 0,
      selectedColumn: null,
      headers: [
        {
          title: "Nom",
          value: "TXT_LastName",
          key: "TXT_LastName",
          sortable: false,
        },
        {
          title: "Prénom",
          value: "TXT_FirstName",
          key: "TXT_FirstName",
          sortable: false,
        },
        {
          title: "Email",
          value: "TXT_Email",
          key: "TXT_Email",
          sortable: false,
        },
        {
          title: "BU Niveau 1",
          value: "TXT_BusinessUnitLevel1",
          key: "TXT_BusinessUnitLevel1",
          sortable: false,
        },
        {
          title: "BU Niveau 2",
          value: "TXT_BusinessUnitLevel2",
          key: "TXT_BusinessUnitLevel2",
          sortable: false,
        },
        {
          title: "BU Niveau 3",
          value: "TXT_BusinessUnitLevel3",
          key: "TXT_BusinessUnitLevel3",
          sortable: false,
        },
        {
          title: "BU Niveau 4",
          value: "TXT_BusinessUnitLevel4",
          key: "TXT_BusinessUnitLevel4",
          sortable: false,
        },
        {
          title: "BU Niveau 5",
          value: "TXT_BusinessUnitLevel5",
          key: "TXT_BusinessUnitLevel5",
          sortable: false,
        },
        {
          title: "Parcours inscrits",
          value: "NUM_NumberOfActiveSubscriptions",
          key: "NUM_NumberOfActiveSubscriptions",
          sortable: false,
        },
      ],
    };
  },

  computed: {},
  watch: {
    options: {
      handler() {
        this.getTrainees();
      },
      deep: true,
    },
  },

  methods: {
    async initializeCacheBusting() {
      let cacheVersion = localStorage.getItem("cacheVersion");
      if (!cacheVersion) {
        const response = await axios.get(
          `${base_url}${trainees_urls.get_all}`,
          header
        );
        cacheVersion = response.data.results.cache_version;
        localStorage.setItem("cacheVersion", cacheVersion);
      }
      return cacheVersion;
    },

    updateCacheBusting(newCacheVersion) {
      localStorage.setItem("cacheVersion", newCacheVersion);
    },

    async getTrainees(resetPage = false) {
      if (resetPage) {
        this.currentPage = 1;
        this.options.page = 1;
      }

      this.loading = true;
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${trainees_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${cacheVersion}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        this.totalTrainees = response.data.count;
        this.trainees = response.data.results.results.map((trainee) => ({
          ...trainee,
          NUM_NumberOfActiveSubscriptions:
            trainee.NUM_NumberOfActiveSubscriptions || 0,
        }));
      } catch (error) {
        console.error("Error fetching trainees:", error);
      } finally {
        this.loading = false;
      }

      this.checkForUpdates();
    },

    async checkForUpdates() {
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${
        trainees_urls.get_all
      }?page=${page}&page_size=${itemsPerPage}&timestamp=${new Date().getTime()}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        if (
          response.data.results.cache_version !==
          localStorage.getItem("cacheVersion")
        ) {
          this.updateCacheBusting(response.data.results.cache_version);
          await this.getTrainees();
        }
      } catch (error) {
        console.error("Error checking for updates:", error);
      }
    },

    resetSearch() {
      this.search = "";
      this.getTrainees(true);
    },

    getItemProperty(item, property) {
      return item[property];
    },
  },

  mounted() {
    document.title = "Apprenant·es";
    this.getTrainees();
  },
};
</script>

<style lang="scss">
.trainees .data-table .v-table .v-data-table__td:last-child {
  width: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  flex-wrap: wrap;
  .v-input__control {
    margin-top: -3px;
  }
}

.trainees .data-table .v-table {
  .v-data-table__th:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .v-data-table-header__content {
      margin-left: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        text-align: center;
      }
    }
  }
}
</style>
