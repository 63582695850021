import { createStore } from "vuex";
import auth from "./modules/auth"; // Import the auth module

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth, // Add the auth module to the main store's modules
  },
});
