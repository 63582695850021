<template>
  <div class="details trainee">
    <NavBar></NavBar>
    <div class="title-block">
      <button class="return" onclick="history.back()">
        <v-icon>mdi-keyboard-return</v-icon>
      </button>
      <h1 class="text-h2 my-8 text-center">Détails de l'Apprenant·e</h1>
    </div>
    <div class="mx-16 pb-10 pl-16">
      <v-card class="data-table">
        <v-card-title
          class="title-details rounded-lg text-center d-flex justify-center"
        >
          Informations
        </v-card-title>
        <div class="d-flex flex-row align-start background-details flex-wrap">
          <v-list class="details-content d-flex flex-wrap pt-0 pb-8 w-100">
            <div class="w-50_fix">
              <v-list-item
                class="w-100 pa-0"
                v-for="key in keyOrder1"
                :key="key"
              >
                <div class="details-table-infos px-10 py-3">
                  <div class="details-table-infos__cell">
                    <div>
                      <strong>{{ keyTextMap[key] }} :</strong>
                    </div>
                    <div class="details-table-infos-content">
                      {{ trainee[key] }}
                    </div>
                  </div>
                  <div
                    class="details-table-infos__edit"
                    @click="openUpdateDialog(key)"
                  >
                    <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                  </div>
                </div>
              </v-list-item>
            </div>

            <div class="w-50">
              <v-list-item
                class="w-100 pa-0"
                v-for="key in keyOrder2"
                :key="key"
              >
                <div class="details-table-infos px-10 py-3">
                  <div class="details-table-infos__cell">
                    <div :class="{ 'text-primary': key === 'managements' }">
                      <strong>{{ keyTextMap[key] }} :</strong>
                    </div>
                    <div class="details-table-infos-content">
                      <div v-if="key === 'managements'">
                        <div
                          v-for="manager in trainee.RelatedManagers"
                          :key="manager.ID_D_Management"
                        >
                          {{ manager.TXT_Email }}
                        </div>
                      </div>
                      <template v-else>
                        {{ trainee[key] }}
                      </template>
                    </div>
                  </div>

                  <div
                    v-if="key !== 'managements'"
                    class="details-table-infos__edit"
                    @click="openUpdateDialog(key)"
                  >
                    <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                  </div>
                </div>
              </v-list-item>

              <div class="businessunits v-list-item__content px-10 py-3">
                <div class="d-flex justify-space-between align-center">
                  <h4 class="mb-2">
                    <strong>BusinessUnits :</strong>
                  </h4>
                  <div
                    class="details-table-infos__edit"
                    @click="openUpdateDialog('BusinessUnit')"
                  >
                    <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                  </div>
                </div>

                <li class="businessunits" v-for="key in keyOrder3" :key="key">
                  {{ trainee[key] }}
                </li>
              </div>
            </div>

            <div class="w-100">
              <h2 class="px-10 mt-6 text-h6 font-weight-bold">
                Commentaires :
                <v-btn icon class="ml-2" @click="openAddCommentDialog">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </h2>

              <div class="comments-list px-10 mt-4 d-flex">
                <v-card
                  v-for="comment in reversedComments"
                  :key="comment.ID_FS_CommentsTrainee"
                  class="comment-block pa-2 mb-4 rounded-lg"
                  color="secondary"
                >
                  <div class="comment-header d-flex justify-between">
                    <h3 class="comment-date mb-2 d-flex">
                      <span class="comment-date__user">
                        {{ comment.ID_NDAppUser }}
                      </span>
                      &nbsp;-&nbsp;
                      <span class="comment-date__date">
                        {{ formatDate(comment.DAT_Created) }}
                      </span>
                    </h3>
                    <div class="comment-actions">
                      <div
                        class="comment-edit"
                        @click="openCommentUpdateDialog(comment)"
                      >
                        <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                      </div>
                      <div
                        class="comment-delete"
                        @click="openDeleteDialog(comment)"
                      >
                        <v-icon icon="mdi-delete" size="x-large"></v-icon>
                      </div>
                    </div>
                  </div>
                  <p class="comment-text">{{ comment.TXT_Comment }}</p>
                </v-card>
              </div>
            </div>
          </v-list>
        </div>
        <TraineeTBSTable />
      </v-card>
    </div>

    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-textarea
            v-if="fieldToUpdate !== 'BusinessUnit'"
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newFieldValue"
            :rules="[rules.required]"
            color="primary"
          ></v-textarea>

          <v-select
            v-if="fieldToUpdate === 'BusinessUnit'"
            v-model="newFieldValue"
            :items="formattedBusinessUnits"
            item-value="value"
            item-text="title"
            label="Sélectionner une Business Unit"
            :loading="isLoading"
          >
            <template #prepend-item>
              <v-text-field
                v-model="search"
                label="Rechercher dans la liste..."
                variant="underlined"
                single-line
                color="primary"
                class="px-6"
              ></v-text-field>
            </template>
          </v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Annuler</v-btn>
          <v-btn color="primary" text @click="confirmUpdate">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addCommentDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">Ajouter un commentaire</v-card-title>
        <v-card-text>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newComment"
            :rules="[rules.required]"
            color="primary"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addCommentDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="addComment">Ajouter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">Supprimer le commentaire</v-card-title>
        <v-card-text
          >Voulez-vous vraiment supprimer ce commentaire ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="deleteComment">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import TraineeTBSTable from "@/components/TraineeTBSTable.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import {
  base_url,
  trainees_urls,
  bu_urls,
  comments_urls,
  user_urls,
  header,
} from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "TraineeSlug",
  components: {
    NavBar,
    TraineeTBSTable,
  },
  data() {
    return {
      trainee: {
        comments: [],
      },
      keyTextMap: {
        TXT_LastName: "Nom",
        TXT_FirstName: "Prénom",
        TXT_Email: "Email",
        TXT_BirthState: "Birthstate",
        DAT_BirthDate: "Date de naissance",
        TXT_PhoneNumber: "Numéro de tel",
        TXT_JobTitle: "Jobtitle",
        ID_D_BusinessUnit: "Business Unit",
        managements: "Management",
      },
      keyOrder1: [
        "TXT_LastName",
        "TXT_FirstName",
        "TXT_Email",
        "TXT_BirthState",
        "DAT_BirthDate",
        "TXT_PhoneNumber",
      ],
      keyOrder2: ["TXT_JobTitle", "managements"],
      keyOrder3: [
        "TXT_BusinessUnitLevel1",
        "TXT_BusinessUnitLevel2",
        "TXT_BusinessUnitLevel3",
        "TXT_BusinessUnitLevel4",
        "TXT_BusinessUnitLevel5",
      ],

      dialog: false,
      dialogTitle: "",
      newFieldValue: "",
      fieldToUpdate: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      currentCommentID: null,
      addCommentDialog: false,
      newComment: "",
      deleteDialog: false,
      commentToDelete: null,

      Managements: [],
      BusinessUnits: [],
      isLoading: false,
      search: "",

      rules: {
        required: (value) => !!value || "Ce champ est requis.",
      },
    };
  },

  methods: {
    getUserDetails(id) {
      const url = `${base_url}${user_urls.get_details(id)}`;
      return axios.get(url, header);
    },

    getTraineeDetails() {
      const traineeId = this.$route.params.id;
      const url = `${base_url}${trainees_urls.get_details(traineeId)}`;
      const commentsUrl = `${base_url}${comments_urls.trainee_all}`; // Fetch all comments

      axios
        .get(url, header)
        .then((response) => {
          this.trainee = {
            ...response.data, // As your API returns an array, we need the first object from the array
          };
          // Get user details
          return this.getUserDetails(this.$store.state.auth.id);
        })
        .then((userResponse) => {
          // Replace the user ID with username
          this.trainee.TXT_LastEditedBy = userResponse.data.username;
          // Fetch comments
          return axios.get(commentsUrl, header);
        })
        .then((commentsResponse) => {
          // Filter comments based on traineeId
          this.trainee.comments = commentsResponse.data.filter(
            (comment) => comment.ID_D_Trainee === parseInt(traineeId)
          );
          // Get user details for each unique user ID in the comments
          const uniqueUserIds = [
            ...new Set(
              this.trainee.comments.map((comment) => comment.ID_NDAppUser)
            ),
          ];
          return Promise.all(
            uniqueUserIds.map((userId) => this.getUserDetails(userId))
          );
        })
        .then((userResponses) => {
          // Replace the user ID with username in comments
          userResponses.forEach((userResponse) => {
            const username = userResponse.data.username;
            this.trainee.comments.forEach((comment) => {
              if (comment.ID_NDAppUser === userResponse.data.id) {
                comment.ID_NDAppUser = username;
              }
            });
          });
        })
        .catch((error) => {
          console.error("Error fetching theme details:", error);
        });
    },

    getBusinessUnits() {
      const url = `${base_url}${bu_urls.get_all}`;
      this.isLoading = true;
      axios
        .get(url, header)
        .then((response) => {
          this.BusinessUnits = response.data.results.results;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error("Error fetching Business units:", error);
          this.isLoading = false;
        });
    },

    openUpdateDialog(key) {
      this.fieldToUpdate = key;
      if (key === "BusinessUnit") {
        this.getBusinessUnits();
        this.dialogTitle = "Mldifier la Business Unit";
        this.newFieldValue = this.trainee.ID_D_BusinessUnit;
      } else {
        this.dialogTitle = `Modifier ${this.keyTextMap[key]}`;
        this.newFieldValue = this.trainee[key];
      }

      this.dialog = true;
    },

    confirmUpdate() {
      if (this.newFieldValue) {
        if (this.fieldToUpdate === "BusinessUnit") {
          this.updateTrainee("ID_D_BusinessUnit", this.newFieldValue);
        } else if (this.fieldToUpdate === "TXT_Comment") {
          this.updateComment(this.currentCommentID, this.newFieldValue);
        } else {
          this.updateTrainee(this.fieldToUpdate, this.newFieldValue);
        }
        this.dialog = false;
      }
    },

    updateComment(commentId, newComment) {
      const url = `${base_url}${comments_urls.trainee_details(commentId)}`;

      axios
        .put(url, { TXT_Comment: newComment }, header)
        .then(() => {
          const commentIndex = this.trainee.comments.findIndex(
            (comment) => comment.ID_FS_CommentsTrainee === commentId
          );
          this.trainee.comments[commentIndex].TXT_Comment = newComment;

          this.snackbarMessage = `Le commentaire a été mis à jour.`;
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          console.error("Error updating comment:", error);

          this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du <strong>Commentaire</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    updateTrainee(field, newValue) {
      // Update the trainee on the server
      const traineeId = this.$route.params.id;
      const url = `${base_url}${trainees_urls.update(traineeId)}`;

      const updatedData = {
        ...this.trainee,
        [field]: newValue,
      };

      axios
        .put(url, updatedData, header)
        .then((response) => {
          this.trainee = response.data;

          this.getTraineeDetails();

          this.snackbarMessage = `Le champ <strong>${this.keyTextMap[field]}</strong> a été mis à jour`;
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          console.error(error);

          this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du champ <strong>${this.keyTextMap[field]}</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    formatDate(date) {
      const dateObject = new Date(date);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    },

    openCommentUpdateDialog(comment) {
      this.dialogTitle = `Modifier le commentaire de ${this.formatDate(
        comment.DAT_Created
      )}`;
      this.fieldToUpdate = "TXT_Comment";
      this.newFieldValue = comment.TXT_Comment;
      this.dialog = true;
      this.currentCommentID = comment.ID_FS_CommentsTrainee;
    },

    openAddCommentDialog() {
      this.newComment = ""; // Reset the new comment field
      this.addCommentDialog = true;
    },

    addComment() {
      const url = `${base_url}${comments_urls.trainee_all}`;

      const commentData = {
        ID_NDAppUser: this.$store.state.auth.id,
        DAT_Created: new Date().toISOString(),
        TXT_Comment: this.newComment,
        ID_D_Trainee: this.$route.params.id,
      };

      axios
        .post(url, commentData, header)
        .then(() => {
          this.getTraineeDetails(); // Fetch subtheme details again to update the comments list

          this.snackbarMessage = `Votre commentaire a été ajouté avec succès.`;
          this.snackbarColor = "success";
          this.snackbar = true;

          this.addCommentDialog = false;
        })
        .catch((error) => {
          console.error("Error adding comment:", error);

          this.snackbarMessage = `Une erreur s'est produite lors de l'ajout du commentaire`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    openDeleteDialog(comment) {
      this.commentToDelete = comment;
      this.deleteDialog = true;
    },

    deleteComment() {
      if (this.commentToDelete) {
        const url = `${base_url}${comments_urls.delete_trainee(
          this.commentToDelete.ID_FS_CommentsTrainee
        )}`;

        axios
          .delete(url, header)
          .then(() => {
            this.getTraineeDetails();

            this.snackbarMessage = `Votre commentaire a été supprimé.`;
            this.snackbarColor = "success";
            this.snackbar = true;

            this.deleteDialog = false;
          })
          .catch((error) => {
            console.error("Error deleting comment:", error);

            this.snackbarMessage = `Erreur lors de la suppression du commentaire`;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
      }
    },
    formatBusinessUnitTitle(levels) {
      const title = levels
        .filter(Boolean)
        .map((item, index) => `${index + 1}.${item}`)
        .join(" - ");
      return title.length > 75 ? title.substring(0, 75) + "..." : title;
    },
  },

  computed: {
    reversedComments() {
      return [...(this.trainee.comments || [])].reverse();
    },

    formattedBusinessUnits() {
      if (!this.BusinessUnits || this.BusinessUnits.length === 0) {
        return [];
      }
      if (!this.search) {
        return this.BusinessUnits.map((bu) => ({
          value: bu.ID_D_BusinessUnit,
          title: this.formatBusinessUnitTitle([
            bu.TXT_BusinessUnitLevel1,
            bu.TXT_BusinessUnitLevel2,
            bu.TXT_BusinessUnitLevel3,
            bu.TXT_BusinessUnitLevel4,
            bu.TXT_BusinessUnitLevel5,
          ]),
        }));
      }
      const searchTerms = this.search
        .toLowerCase()
        .split(" ")
        .filter((word) => word.length > 0);
      return this.BusinessUnits.filter((bu) => {
        const buLowerCase = [
          bu.TXT_BusinessUnitLevel1,
          bu.TXT_BusinessUnitLevel2,
          bu.TXT_BusinessUnitLevel3,
          bu.TXT_BusinessUnitLevel4,
          bu.TXT_BusinessUnitLevel5,
        ]
          .filter(Boolean)
          .join(" - ")
          .toLowerCase();
        return searchTerms.every((term) => buLowerCase.includes(term));
      }).map((bu) => ({
        value: bu.ID_D_BusinessUnit,
        title: this.formatBusinessUnitTitle([
          bu.TXT_BusinessUnitLevel1,
          bu.TXT_BusinessUnitLevel2,
          bu.TXT_BusinessUnitLevel3,
          bu.TXT_BusinessUnitLevel4,
          bu.TXT_BusinessUnitLevel5,
        ]),
      }));
    },
  },

  created() {
    this.getTraineeDetails();
  },
  mounted() {
    document.title = "Apprenant·e - Détails";
  },
};
</script>

<style lang="scss">
.details.trainee .data-table .v-table .v-data-table__td {
  width: 100%;
  &:nth-child(1) {
    width: 33%;
  }
}

.w-50_fix {
  width: calc(50% - 1px);
  margin-right: 1px;
}
</style>
