<template>
  <v-icon @click="emitUpdateDialogAction" class="actions_small"
    >mdi-delete</v-icon
  >
  <v-dialog
    v-model="dialogTraineeAction"
    transition="dialog-top-transition"
    persistent
    max-width="800"
    min-width="400"
  >
    <v-card class="rounded-lg pa-4" color="dialog">
      <v-card-title class="headline">{{ ActionDeleteTitle }}</v-card-title>
      <v-card-text>
        <div class="field_form">
          <label for="nouvelleDateDeFin" class="label_datePicker"
            >Nouvelle Date de fin de Formation :
          </label>
          <input
            type="date"
            prepend-icon="mdi-calendar"
            id="NouvelleDateDeFin"
            v-model="newSelectedEndDate"
          />
        </div>
        <div class="field_form">
          <label for="DateExam" class="label_datePicker"
            >Nouvelle Date d'Examen (optionel):
          </label>
          <input type="date" id="DateExam" v-model="newExamDate" />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialogTraineeAction = false"
          >Annuler</v-btn
        >
        <v-btn
          color="primary"
          text
          @click="
            desinscriptionTrainingblocSubscription(
              field,
              item,
              action,
              newSelectedEndDate,
              newExamDate
            ),
              (dialogTraineeAction = false)
          "
          >Confirmer</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
    <p v-html="snackbarMessage"></p>
  </v-snackbar>
</template>

<script>
import authExpirationMixin from "@/mixins/isLogged.js";

import axios from "axios";
import {
  base_url,
  trainingblocsubscriptions_urls,
  header,
} from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],

  props: {
    ActionDeleteTitle: String,
    item: Object,
    traineesData: {
      type: Array,
      required: true,
    },
  },
  emits: [
    "refresh-training-subscriptions",
    "selected-end-date-changed",
    "exam-date-changed",
    "handleSelectedEndDateChanged",
    "handleExamDateChanged",
  ],
  data() {
    return {
      dialogTraineeAction: false,
      newSelectedEndDate: null,
      newSelectedExamDate: null,
      selectedExamDate: null,
      newExamDate: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",
    };
  },

  watch: {
    newSelectedEndDate(newEndDate) {
      this.$emit("selected-end-date-changed", newEndDate);
    },

    newExamDate(newExamDate) {
      this.$emit("exam-date-changed", newExamDate);
    },
  },

  methods: {
    async desinscriptionTrainingblocSubscription(
      field,
      item,
      action,
      newSelectedEndDate,
      newExamDate
    ) {
      try {
        const selectedTrainingBlocSubscription =
          item.ID_FS_TrainingBlocSubscription;
        const currentTrainingblocSubscription =
          await this.fetchCurrentSubscription(selectedTrainingBlocSubscription);

        const currentSubscriptionEnd = new Date(
          currentTrainingblocSubscription.DAT_SubscriptionEnd
        );
        const currentSubscriptionStart = new Date(
          currentTrainingblocSubscription.DAT_SubscriptionStart
        );

        const newSubscriptionEnd = new Date(newSelectedEndDate);

        if (newSubscriptionEnd < currentSubscriptionStart) {
          this.snackbarMessage = `La nouvelle date de fin doit être après la date de début initiale.`;
          this.snackbarColor = "error";
          this.snackbar = true;
          return;
        }

        if (newSubscriptionEnd > currentSubscriptionEnd) {
          this.snackbarMessage = `La nouvelle date de fin commencer avant à la date de fin initiale.`;
          this.snackbarColor = "error";
          this.snackbar = true;
          return;
        }

        const updatedselectedTrainingBlocSubscription = {
          DAT_SubscriptionEnd: newSubscriptionEnd.toISOString().split("T")[0], // Convertir en format ISO pour la date
          TXT_SubscriptionStatus: "desinscrit.e",
          DAT_FinalTestScheduled: newExamDate
            ? new Date(newExamDate).toISOString().split("T")[0]
            : null,
          BIT_Cancelled: true,
          BIT_Extention: false,
          BIT_Resumption: false,
        };

        const url = `${base_url}${trainingblocsubscriptions_urls.update(
          selectedTrainingBlocSubscription
        )}`;
        await axios.put(url, updatedselectedTrainingBlocSubscription, header);

        this.snackbarMessage = "La formation a été désinscrite avec succès";
        this.snackbarColor = "success";
        this.snackbar = true;
        this.$emit("refresh-training-subscriptions");
      } catch (error) {
        this.snackbarMessage = `La désinscription n'a pas été prise en compte`;
        this.snackbarColor = "error";
        this.snackbar = true;
        console.error("Erreur lors de la désinscription:", error);
      }
      this.dialogTraineeAction = false;
    },

    async desinscriptionAllTrainingblocSubscriptionForATrainee() {
      try {
        const traineeId = this.selectedTrainee.ID_D_Trainee;
        const url = `${base_url}${trainingblocsubscriptions_urls.get_all}`;

        const response = await axios.get(url, header);
        const subscriptions = response.data;

        const newExamDate = this.newExamDate
          ? new Date(this.newExamDate)
          : null;

        for (const subscription of subscriptions) {
          if (parseInt(subscription.ID_D_Trainee) === parseInt(traineeId)) {
            const subscriptionId = subscription.ID_FS_TrainingBlocSubscription;
            const updateUrl = `${base_url}${trainingblocsubscriptions_urls.get_details(
              subscriptionId
            )}`;

            const updatedSubscription = {
              DAT_SubscriptionEnd: this.newSelectedEndDate,
              TXT_SubscriptionStatus: "desinscrit.e",
              DAT_FinalTestScheduled: newExamDate
                ? newExamDate.toISOString().split("T")[0]
                : null,
              BIT_Cancelled: true,
              BIT_Extention: false,
              BIT_Resumption: false,
            };

            await axios.put(updateUrl, updatedSubscription, header);

            if (this.newEndDate) {
              this.snackbarMessage =
                "Les sessions de formation ont été désinscrites avec succès";
              this.snackbarColor = "success";
              this.snackbar = true;
            }
          }
        }

        this.$emit("refresh-training-subscriptions");
      } catch (error) {
        this.snackbarMessage =
          "Erreur lors de la désinscription des sessions de formation";
        this.snackbarColor = "error";
        this.snackbar = true;
      }
      this.dialogTraineeAction = false;
    },

    // --- Méthodse liées --- //

    emitUpdateDialogAction() {
      this.dialogTraineeAction = true;
    },

    async fetchCurrentSubscription(selectedTrainingBlocSubscription) {
      const url = `${base_url}${trainingblocsubscriptions_urls.get_details(
        selectedTrainingBlocSubscription
      )}`;
      const CurrentSubscription = await axios.get(url, header);
      return CurrentSubscription.data;
    },

    async pauseCurrentSubscription(selectedTrainingBlocSubscription) {
      const url = `${base_url}${trainingblocsubscriptions_urls.get_details(
        selectedTrainingBlocSubscription
      )}`;

      const updatedSubscription = {
        ...this.CurrentSubscription,
        BIT_Cancelled: true,
      };

      try {
        await axios.put(url, updatedSubscription, header);
      } catch (error) {
        console.error(
          "Erreur lors de la mise en pause de la souscription :",
          error
        );
      }
    },

    handleSelectedEndDateChanged(newEndDate) {
      this.newSelectedEndDate = newEndDate;
      this.$emit("update:endDate", newEndDate);
    },
    handleExamDateChanged(newExamDate) {
      this.selectedExamDate = newExamDate;
      this.$emit("update:examDate", newExamDate);
    },
  },
};
</script>
