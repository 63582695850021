// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";
// import { VDataTable } from "vuetify/labs/VDataTable";
// import { VDataTableServer } from "vuetify/labs/VDataTable";
// import { VDatePicker } from "vuetify/labs/VDatePicker";

export default createVuetify({
  components: {
    // VDataTable,
    // VDataTableServer,
    // VDatePicker,
  },
  theme: {
    defaultTheme: "dark",
    themes: {
      dark: {
        dark: true,
        colors: {
          primary: "#D10565",
          secondary: "#084B84",
          tertiary: "#1e2630",
          accent: "#343840bf",
          dialog: "#212429",
          table: "#23252ACC",
        },
      },
    },
  },
});
