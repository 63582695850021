<template>
  <v-container fluid class="max-height d-flex align-center justify-center">
    <div class="d-flex justify-center align-center flex-column">
      <v-icon color="red" class="alert-icon">mdi-alert-octagon</v-icon>
      <h1 class="alert-title">404</h1>
      <h2 class="">Oups! Page non trouvée.</h2>
      <p class="mt-2">
        Retourner sur <router-link to="/">l'accueil</router-link>
      </p>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",
  methods: {
    goBack() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.max-height {
  height: 100vh;
}

.max-height > div {
  gap: 20px;
}
.alert-icon {
  font-size: 80px;
}
.alert-title {
  font-size: 140px;
  line-height: normal;
}
</style>
