<template>
  <div class="details trainingbloc">
    <NavBar></NavBar>
    <div class="title-block">
      <button class="return" onclick="history.back()">
        <v-icon>mdi-keyboard-return</v-icon>
      </button>
      <h1 class="text-h2 my-8 text-center">Détails du Parcours</h1>
    </div>
    <div class="mx-16 pb-10 pl-16">
      <v-card class="data-table">
        <v-card-title
          class="title-details rounded-lg text-center d-flex justify-center"
        >
          Informations
        </v-card-title>
        <div class="d-flex flex-row align-start background-details flex-wrap">
          <v-list class="details-content d-flex flex-wrap pt-0 pb-8 w-100">
            <div class="w-50_fix">
              <!-- column 1 -->
              <v-list-item
                class="w-100 pa-0"
                v-for="key in keyOrder1"
                :key="key"
              >
                <!-- if editable -->
                <div v-if="isEditable(key)">
                  <div class="details-table-infos px-10 py-3">
                    <div class="details-table-infos__cell">
                      <div>
                        <strong>{{ keyTextMap[key] }} :</strong>
                      </div>
                      <div>
                        <v-chip-group>
                          <v-chip
                            v-for="codeLZId in selectedCodeLZ"
                            :key="codeLZId"
                            color="dark"
                            class="ma-2"
                          >
                            {{ getCodeLZText(codeLZId) }}
                          </v-chip>
                        </v-chip-group>
                      </div>
                    </div>
                    <div
                      class="details-table-infos__edit"
                      @click="openUpdateDialog(key)"
                    >
                      <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                    </div>
                  </div>
                </div>
                <!-- if not editable -->
                <div v-else>
                  <div class="details-table-infos px-10 py-3">
                    <div class="details-table-infos__cell">
                      <div>
                        <strong>{{ keyTextMap[key] }} :</strong>
                      </div>
                      <div>
                        {{ trainingbloc[key] }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-list-item>
            </div>

            <div class="w-50">
              <!-- column 2 -->
              <v-list-item
                class="w-100 pa-0"
                v-for="key in keyOrder2"
                :key="key"
              >
                <!-- if editable -->
                <div v-if="isEditable(key)">
                  <div class="details-table-infos px-10 py-3">
                    <div class="details-table-infos__cell">
                      <div>
                        <strong>{{ keyTextMap[key] }} :</strong>
                      </div>
                      <div v-if="!showCodeLZInput">
                        {{ trainingbloc[key] }} -
                        <template
                          v-if="trainingbloc.TXT_TargetType !== 'Aucun'"
                        >
                          {{
                            trainingbloc.NUM_TargetDurationMinutes &&
                            convertMinutesToHours(
                              trainingbloc.NUM_TargetDurationMinutes
                            )
                          }}
                          {{
                            trainingbloc.NUM_TargetScore &&
                            trainingbloc.NUM_TargetScore + " %"
                          }}
                        </template>
                      </div>
                    </div>
                    <div
                      class="details-table-infos__edit"
                      @click="openUpdateDialog(key)"
                    >
                      <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                    </div>
                  </div>
                </div>
                <!-- if not editable -->
                <div v-else>
                  <div class="details-table-infos px-10 py-3">
                    <div class="details-table-infos__cell">
                      <div>
                        <strong>{{ keyTextMap[key] }} :</strong>
                      </div>
                      <div>
                        {{ trainingbloc[key] }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-list-item>
            </div>
          </v-list>

          <TraningBlocSubscriptionsTable />

          <div class="details-dates mt-6 px-10 d-flex pb-8">
            <div
              class="w-100 pa-0 details-dates__element"
              v-for="key in keyOrder3"
              :key="key"
            >
              <div class="element">
                <div>
                  <strong>{{ keyTextMap[key] }} :</strong>
                </div>
                <div>
                  {{
                    key === "DAT_LMSCreated" ||
                    key === "DAT_LMSLastEdited" ||
                    key === "DAT_NDAppDeletionDate"
                      ? formatDate(trainingbloc[key])
                      : trainingbloc[key]
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>

    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text v-if="fieldToUpdate === 'TXT_TargetType'">
          <v-select
            v-model="trainingbloc.TXT_TargetType"
            :items="targetTypes"
            label="Type d'objectif"
            item-title="text"
            item-value="value"
          ></v-select>
          <v-text-field
            v-model="trainingbloc.NUM_TargetScore"
            label="Score"
            type="number"
            :disabled="scoreDisabled"
          ></v-text-field>
          <v-text-field
            v-model="trainingbloc.NUM_TargetDurationMinutes"
            label="Temps en minutes"
            type="number"
            :disabled="timeDisabled"
          ></v-text-field>
        </v-card-text>
        <v-card-text v-else>
          <v-select
            v-if="fieldToUpdate === 'TXT_CLCursusCodeLZ'"
            v-model="selectedCodeLZ"
            :items="filteredCodelz"
            chips
            label="Codes LZ"
            multiple
            item-title="text"
            item-value="id"
          >
            <template #prepend-item>
              <v-text-field
                v-model="search"
                label="Rechercher dans la liste..."
                variant="underlined"
                single-line
                color="primary"
                class="px-6"
              ></v-text-field>
            </template>
          </v-select>

          <v-textarea
            v-else
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newFieldValue"
            :rules="[rules.required]"
            color="primary"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="(dialog = false), getTrainingblocDetails()"
            >Annuler</v-btn
          >
          <v-btn
            v-if="fieldToUpdate === 'TXT_CLCursusCodeLZ'"
            color="primary"
            text
            @click="updateTrainingblocCodeLZ"
            >Confirmer</v-btn
          >
          <v-btn
            v-else-if="fieldToUpdate === 'TXT_TargetType'"
            color="primary"
            text
            @click="confirmUpdate"
            >Confirmer</v-btn
          >
          <v-btn v-else color="primary" text @click="confirmUpdate"
            >Confirmer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import TraningBlocSubscriptionsTable from "@/components/TraningBlocSubscriptionsTable.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import {
  base_url,
  header,
  trainingblocs_urls,
  codelz_urls,
} from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "trainingblocSlug",
  components: {
    NavBar,
    TraningBlocSubscriptionsTable,
  },
  watch: {
    "trainingbloc.TXT_TargetType": "handleTargetTypeChange",
    search: {
      handler() {
        this.filteredCodelz = this.generateItemsFromCodelz(
          this.codelz,
          this.trainingbloc.ID_D_TrainingBloc
        );
      },
      immediate: true,
    },
  },
  data() {
    return {
      keyTextMap: {
        TXT_TrainingBlocName: "Nom du parcours",
        TXT_TrainingBlocDescription: "Description",
        ID_D_TrainingBloc: "ID TrainingBloc",
        NUM_TargetDurationMinutes: "Objectif de temps en heures",
        NUM_TargetScore: "Objectif de score",
        TXT_NDContentCode: "Groupe Code",
        TXT_LMSTrainingBlocID: "ID Drillster",
        TXT_CLCursusCodeLZ: "Codes LZ",

        DAT_LMSCreated: "Création",
        // TXT_LastEditedBy: "Modifié par",
        DAT_LMSLastEdited: "Dernière modification",
        DAT_NDAppDeletionDate: "Date de suppression",
        TXT_TargetType: "Type de parcours",
      },
      keyOrder1: [
        "TXT_TrainingBlocName",
        "TXT_TrainingBlocDescription",
        "TXT_CLCursusCodeLZ",
      ],
      keyOrder2: [
        "TXT_TargetType",
        "TXT_NDContentCode",
        "TXT_LMSTrainingBlocID",
      ],
      keyOrder3: [
        "DAT_LMSCreated",
        "DAT_LMSLastEdited",
        // , "TXT_LastEditedBy"
      ],
      keyOrder4: ["TXT_CLCursusCodeLZ"],

      targetTypes: ["Score", "Temps", "Aucun"],
      selectedTargetType: [],
      trainingbloc: [],
      codelz: [],
      filteredCodelz: [],
      selectedCodeLZ: [],
      selectedCodeLZId: [],
      selectedCodeLZValues: [],
      currentSelectedCodeLZ: [],
      codesLZToAdd: [],
      codesLZToRemove: [],
      showCodeLZInput: false,
      originalTargetType: null,
      updatingTargetType: false,
      TXT_TargetType: null,
      originalNumTargetDurationMinutes: null,
      originalNumTargetScore: null,
      dialog: false,
      dialogTraineeAction: false,
      dialogTitle: "",
      selectAction: "",
      newFieldValue: "",
      fieldToUpdate: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",
      search: "",
      rules: {
        required: (value) => !!value || "Ce champ est requis.",
      },
    };
  },
  computed: {
    scoreDisabled() {
      return this.trainingbloc.TXT_TargetType !== "Score";
    },
    timeDisabled() {
      return this.trainingbloc.TXT_TargetType !== "Temps";
    },
  },
  methods: {
    generateItemsFromCodelz(codelz, trainingblocId) {
      const filteredCodelz = this.search
        ? codelz.filter((codeLZ) => {
            const searchTerms = this.search
              .toLowerCase()
              .split(" ")
              .filter((word) => word.length > 0);
            const codeLZText = [
              codeLZ.TXT_CLCursusCodeLZ,
              codeLZ.Domain?.NUM_CLDomainId,
              codeLZ.Domain?.NUM_CLDomainName,
            ]
              .filter(Boolean)
              .join(" - ")
              .toLowerCase();
            return searchTerms.every((term) => codeLZText.includes(term));
          })
        : codelz;

      return filteredCodelz.map((codeLZ) => ({
        text:
          codeLZ.TXT_CLCursusCodeLZ +
          " - " +
          (codeLZ.Domain?.NUM_CLDomainName ?? "N/A"),
        value: codeLZ.TXT_CLCursusCodeLZ,
        selected: codeLZ.ID_D_TrainingBloc === trainingblocId,
        id: codeLZ.ID_Ref_DRLGroups_CLCursusCodeLZ,
        NUM_CLDomainId: codeLZ.Domain?.NUM_CLDomainId ?? "N/A",
        TXT_CLCursusCodeLZ: codeLZ.TXT_CLCursusCodeLZ,
        NUM_CLDomainName: codeLZ.Domain?.NUM_CLDomainName ?? "N/A",
      }));
    },
    getTrainingblocDetails() {
      const trainingblocId = this.$route.params.id;
      const trainingblocUrl = `${base_url}${trainingblocs_urls.get_details(
        trainingblocId
      )}`;
      const trainingblocPromise = axios.get(trainingblocUrl, header);
      const codelzPromise = axios.get(
        `${base_url}${codelz_urls.get_all}`,
        header
      );

      Promise.all([trainingblocPromise, codelzPromise])
        .then((responses) => {
          const trainingblocResponse = responses[0];
          this.trainingbloc = {
            ...trainingblocResponse.data,
          };
          this.originalTargetType = this.trainingbloc.TXT_TargetType;
          this.traineesData = this.trainingbloc.Trainees;

          const codelzResponse = responses[1];
          this.codelz = codelzResponse.data;

          const trainingblocId = this.trainingbloc.ID_D_TrainingBloc;
          const items = this.generateItemsFromCodelz(
            this.codelz,
            trainingblocId
          );

          console.log(trainingblocResponse.data);

          this.filteredCodelz = items;

          this.selectedCodeLZ = [
            ...new Set(
              this.codelz
                .filter((codeLZ) => codeLZ.ID_D_TrainingBloc === trainingblocId)
                .map((codeLZ) => codeLZ.ID_Ref_DRLGroups_CLCursusCodeLZ)
            ),
          ];
        })
        .catch((error) => {
          console.error("Error fetching parcours details or codelz:", error);
        });
    },

    getCodelzOfTheTrainingbloc() {
      const url = `${base_url}${codelz_urls.get_all}`;

      axios
        .get(url, header)
        .then((response) => {
          this.codelz = response.data;

          const trainingblocId = this.trainingbloc.ID_D_TrainingBloc;
          const items = this.generateItemsFromCodelz(
            this.codelz,
            trainingblocId
          );

          this.filteredCodelz = items;

          this.selectedCodeLZ = [
            ...new Set(
              this.codelz
                .filter((codeLZ) => codeLZ.ID_D_TrainingBloc === trainingblocId)
                .map((codeLZ) => codeLZ.ID_Ref_DRLGroups_CLCursusCodeLZ)
            ),
          ];
        })
        .catch((error) => {
          console.error("Error fetching codelz:", error);
        });
    },

    openUpdateDialog(key) {
      if (this.isEditable(key)) {
        this.fieldToUpdate = key;
        this.newFieldValue = this.trainingbloc[key];

        if (key == "TXT_CLCursusCodeLZ" || key == "TXT_TargetType") {
          this.dialogTitle = `Modifier ${this.keyTextMap[key]}`;
          this.dialog = true;
        } else {
          this.dialogTitle = `Modifier ${this.keyTextMap[key]}`;
          this.dialog = true;
        }
      }
    },

    confirmUpdate() {
      if (this.fieldToUpdate === "TXT_TargetType") {
        this.updateTrainingbloc(
          this.fieldToUpdate,
          this.trainingbloc.TXT_TargetType
        );
      } else if (this.newFieldValue !== null) {
        this.updateTrainingbloc(this.fieldToUpdate, this.newFieldValue);
      }
      this.dialog = false;
      this.getTrainingblocDetails();
    },

    updateTrainingbloc(field, newValue) {
      const trainingblocId = this.$route.params.id;
      const url = `${base_url}${trainingblocs_urls.update(trainingblocId)}`;

      const updatedData = {
        NUM_TargetDurationMinutes: this.trainingbloc.NUM_TargetDurationMinutes,
        NUM_TargetScore: this.trainingbloc.NUM_TargetScore,
        TXT_TrainingBlocName: this.trainingbloc.TXT_TrainingBlocName,
        TXT_TargetType: this.trainingbloc.TXT_TargetType || "Aucun", // Ensure not null
        DAT_LMSCreated: this.trainingbloc.DAT_LMSCreated,
        [field]: newValue, // Override the field to update with the new value
        DAT_LMSLastEdited: new Date().toISOString(), // Current date
        TXT_LastEditedBy: this.$store.state.auth.id, // Current user ID from Vuex store
      };

      axios
        .put(url, updatedData, header)
        .then((response) => {
          this.trainingbloc = response.data;
          this.getTrainingblocDetails();

          this.snackbarMessage = `Le champ <strong>${this.keyTextMap[field]}</strong> a été mis à jour`;
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    updateTrainingblocCodeLZ() {
      const trainingblocId = this.$route.params.id;

      // Code LZ of the TB
      const currentSelectedCodeLZ = this.codelz
        .filter((codeLZ) => codeLZ.ID_D_TrainingBloc == trainingblocId)
        .map((codeLZ) => codeLZ.ID_Ref_DRLGroups_CLCursusCodeLZ);

      const selectedCodeLZIds = Array.from(this.selectedCodeLZ);

      // Identify what to add or remove
      const idsToAdd = selectedCodeLZIds.filter(
        (id) => !currentSelectedCodeLZ.includes(id)
      );
      const idsToRemove = currentSelectedCodeLZ.filter(
        (id) => !selectedCodeLZIds.includes(id)
      );

      // Actions to add the code LZ from the TB
      idsToAdd.forEach((idToAdd) => {
        const codeLZToAdd = this.filteredCodelz.find(
          (codeLZ) => codeLZ.id === idToAdd
        );

        if (codeLZToAdd) {
          const url = `${base_url}${codelz_urls.update(codeLZToAdd.id)}`;
          const updatedData = {
            ID_D_TrainingBloc: parseInt(trainingblocId),
            ID_Ref_DRLGroups_CLCursusCodeLZ: codeLZToAdd.id,
            TXT_CLCursusCodeLZ: codeLZToAdd.value,
            NUM_CLDomainId: codeLZToAdd.NUM_CLDomainId,
          };

          axios
            .put(url, updatedData, header)
            .then((response) => {
              this.codeLZ = response.data;
              // Update the code LZ of the TB
              this.getCodelzOfTheTrainingbloc();
              // Afficher la snackbar
              this.snackbarMessage = "Code LZ ajouté avec succès";
              this.snackbarColor = "success";
              this.snackbar = true;
            })
            .catch((error) => {
              console.error(error);
              this.snackbarMessage = "Erreur lors de l'ajout du code LZ";
              this.snackbarColor = "error";
              this.snackbar = true;
            });
        }
      });

      // Action to remove the code LZ from the TB
      idsToRemove.forEach((idToRemove) => {
        const codeLZToRemove = this.filteredCodelz.find(
          (codeLZ) => codeLZ.id === idToRemove
        );

        if (codeLZToRemove) {
          const url = `${base_url}${codelz_urls.update(codeLZToRemove.id)}`;
          const updatedData = {
            ID_D_TrainingBloc: null,
            ID_Ref_DRLGroups_CLCursusCodeLZ: codeLZToRemove.id,
            TXT_CLCursusCodeLZ: codeLZToRemove.value,
            NUM_CLDomainId: codeLZToRemove.NUM_CLDomainId,
          };

          axios
            .put(url, updatedData, header)
            .then((response) => {
              this.codeLZ = response.data;
              // Update the code LZ of the TB
              this.getCodelzOfTheTrainingbloc();
              // Afficher la snackbar
              this.snackbarMessage = "Code LZ retiré avec succès";
              this.snackbarColor = "success";
              this.snackbar = true;
            })
            .catch((error) => {
              console.error(error);
              this.snackbarMessage = "Erreur lors du retrait du code LZ";
              this.snackbarColor = "error";
              this.snackbar = true;
            });
        }
      });

      this.dialog = false;
    },

    formatDate(date) {
      if (!date) return "N/A";
      const dateObject = new Date(date);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    },

    isEditable(key) {
      const editableKeys = [
        "TXT_CLCursusCodeLZ",
        "NUM_TargetDurationMinutes",
        "NUM_TargetScore",
        "TXT_TargetType",
      ];
      return editableKeys.includes(key);
    },

    getCodeLZText(codeLZId) {
      const codeLZItem = this.filteredCodelz.find(
        (item) => item.id === codeLZId
      );
      if (codeLZItem) {
        return `${codeLZItem.TXT_CLCursusCodeLZ} - ${codeLZItem.NUM_CLDomainName}`;
      }
      return "";
    },

    handleCodeLZSelectionChange(selectedItems) {
      this.selectedCodeLZ = new Set(selectedItems);
    },

    handleTargetTypeChange() {
      const newVal = this.trainingbloc.TXT_TargetType;

      if (newVal !== this.originalTargetType) {
        if (newVal === "Aucun") {
          this.trainingbloc.NUM_TargetDurationMinutes = null;
          this.trainingbloc.NUM_TargetScore = null;
        } else if (newVal === "Temps") {
          this.trainingbloc.NUM_TargetScore = null;
        } else if (newVal === "Score") {
          this.trainingbloc.NUM_TargetDurationMinutes = null;
        }
      } else if (newVal === this.originalTargetType) {
        this.getTrainingblocDetails();
      }
    },

    convertMinutesToHours(minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours} heures ${
        remainingMinutes > 0 ? remainingMinutes + " minutes" : ""
      }`;
    },
  },
  created() {
    this.getTrainingblocDetails(); // Execute the getTrainingblocDetails method on component creation
    this.getCodelzOfTheTrainingbloc(); // Execute the getCodelzOfTheTrainingbloc method on component mount
  },
};
</script>

<style lang="scss">
.w-50_fix {
  width: calc(50% - 1px);
  margin-right: 1px;
}
.CodesLZ {
  margin-top: 10px;
}
</style>
